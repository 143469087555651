import {Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {OrderItem} from '@nxt/model-core';
import {EqipCartService, IEqipCartDocument} from '../../../../services/eqip.cart.service';
import {CommonModule} from '@angular/common';
import {InputCounterComponent} from '../../../../_components/inputs/input-counter/eqip-input-counter.component';
import {Router, RouterLink} from '@angular/router';
import {trigger, transition, style, animate} from '@angular/animations';
import {FormsModule} from '@angular/forms';
import {debounceTime, Subject} from 'rxjs';
import {IconsComponent} from "../../../../../../../_library/shared/icons/icons.component";
import {AnalyticsService} from "../../../../../../../_library/shared/_services/analytics.service";
import {takeUntil} from "rxjs/operators";
import {OnDestroyPage} from "../../../../../../../_library/shared/_inherited/ondestroy.page";
import {EqipAddProductC} from "../add-product/add-product.component";

@Component({
    selector: 'cart-sidebar',
    imports: [CommonModule, InputCounterComponent, RouterLink, FormsModule, IconsComponent, EqipAddProductC],
    standalone: true,
    template: `
        <div
             @slideOutButton
             class="fixed right-0 top-1/2 z-50 bg-accent-600 text-white w-12 h-32 sm:w-16 sm:h-40 rounded-l-2xl flex items-center justify-center cursor-pointer transition-all duration-300 ease-in-out hover:bg-accent-700 hover:scale-105 shadow-lg hover:shadow-2xl"
             style="transform: translateY(-50%); animation: pulse 3s infinite cubic-bezier(0.4, 0, 0.6, 1);"
             (click)="toggleCart()"
        >
            <div class="relative flex flex-col items-center space-y-1 sm:space-y-2">
                <icon strokeWidth="1.6" name="heroicon-outline-shopping-cart" class="w-8 h-8 sm:w-10 sm:h-10 text-white"></icon>
                <span *ngIf="cart?.checkout.items.length > 0"
                      class="absolute -top-3 -right-1 text-xs bg-red-500 rounded-full px-1.5 py-0.5 sm:px-2 sm:py-1 text-white">
                    {{ cart.checkout.items.length }}
                </span>
            </div>
        </div>

        <div *ngIf="isCartOpen"
             class="fixed inset-0 z-[70] overflow-hidden"
             aria-labelledby="slide-over-title"
             role="dialog"
             aria-modal="true"
        >
            <div
                class="absolute inset-0 bg-gray-700 bg-opacity-75"
                (click)="toggleCart()"
            ></div>
            <div class="fixed inset-y-0 right-0 max-w-full flex" @slideInLeft>
                <div class="w-screen max-w-md bg-white shadow-xl transition-transform duration-500 ease-in-out">
                    <div class="flex h-full flex-col">
                        <div class="px-4 py-6 sm:px-6 bg-accent-600 text-white flex justify-between items-center">
                            <h2 class="text-xl font-semibold" id="slide-over-title">Request for Quote</h2>
                            <button
                                type="button"
                                class="text-white hover:text-gray-300 focus:outline-none"
                                (click)="toggleCart()"
                            >
                                <span class="sr-only">Close panel</span>
                                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </button>
                        </div>
                        <div class="px-4 py-2">
                            <label for="categoryFilter" class="block text-sm font-medium text-gray-700">Filter by
                                Category</label>
                            <select
                                id="categoryFilter"
                                class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-accent-600 focus:border-accent-600 sm:text-sm rounded-md"
                                [(ngModel)]="selectedCategory"
                                (change)="filterItems()"
                            >
                                <option value="all">All</option>
                                <option *ngFor="let category of categories">{{ category }}</option>
                            </select>
                        </div>
                        <button (click)="toggleCustomProductModal()" [ngClass]="{'animate-pulse': cart?.checkout.items.length === 0}"
                                class="bg-accent-600 my-2 mx-4 text-white py-2 px-6 text-sm sm:text-base hover:bg-accent-700 transition duration-300">
                            Add Custom Product/Service
                        </button>
                        <eqip-add-product-c
                            *ngIf="isCustomProductModalOpen"
                            (productAdded)="handleProductAdded($event)"
                            (closeModal)="toggleCustomProductModal()">
                        </eqip-add-product-c>
                        <div class="flex-1 overflow-y-auto px-4 py-6 sm:px-6 scrollbar">
                            <div class="flow-root">
                                <ul class="divide-y divide-gray-200">
                                    <li *ngFor="let item of filteredItems; trackBy: trackByItemId"
                                        class="py-4 flex flex-col space-y-2">
                                        <div class="flex items-center space-x-4">
                                            <div
                                                class="h-12 w-12 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                                <img
                                                    [src]="item.product?.image_urls || 'assets/images/camera_thumbnail.png'"
                                                    alt="Product image"
                                                    class="h-full w-full object-cover object-center cursor-pointer"
                                                    (click)="navigateToProduct(item)"
                                                />
                                            </div>
                                            <div class="flex-grow">
                                                <div
                                                    class="flex justify-between text-xs font-medium text-gray-900 cursor-pointer">
                                                    <h3 class="text-sm font-medium"
                                                        (click)="navigateToProduct(item)">{{ item.name }}</h3>
                                                    <p>{{ item.price | currency }}</p>
                                                </div>
                                                <div class="flex items-start">
                                                    <eqip-input-counter
                                                        [(quantity)]="item.quantity"
                                                        (quantityChange)="updateQuantity(item, item.quantity)"
                                                        sizeClass="text-xs"
                                                        buttonSizeClass="w-5 h-5 text-xs"
                                                        inputSizeClass="w-10 mx-1 py-0.5 text-xs"
                                                    ></eqip-input-counter>
                                                </div>

                                            </div>
                                            <div class="flex space-x-2">
                                                <icon (click)="toggleNotes(item)"
                                                      [ngClass]="{'text-accent-500 hover:text-accent-600': item.notes && item.notes.length > 0, 'text-black hover:text-gray-700': !item.notes || item.notes.length === 0}"
                                                      name="heroicon-outline-pencil-alt"
                                                      class="h-6 w-6 cursor-pointer">
                                                </icon>
                                                <icon (click)="removeItem(item)"
                                                      name="heroicon-outline-trash"
                                                      class="h-6 w-6 text-red-500 cursor-pointer hover:text-red-600 pb-2">
                                                </icon>
                                            </div>
                                        </div>
                                        <div *ngIf="showNotes[item.id]" class="mt-2">
                                        <textarea
                                            class="w-full p-2 border rounded-md text-xs"
                                            rows="2"
                                            [(ngModel)]="item.notes"
                                            (ngModelChange)="onNotesChange(item, $event)"
                                            placeholder="Add a note to this item"
                                        ></textarea>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="border-t border-gray-200 px-4 py-6 sm:px-6">
                            <button
                                [routerLink]="'/u/checkout'"
                                class="w-full flex justify-center items-center rounded-md bg-accent-600 px-6 py-3 text-base font-medium text-white hover:bg-accent-700"
                            >
                                Submit Request for Quote
                            </button>
                            <div class="mt-6 text-center text-sm text-gray-500">
                                <p>
                                    or
                                    <button
                                        class="text-accent-600 hover:text-accent-500 font-medium"
                                        (click)="toggleCart()"
                                    >
                                        Continue Building Quote →
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    animations: [
        trigger('slideInLeft', [
            transition(':enter', [
                style({transform: 'translateX(100%)'}),
                animate('500ms ease-out', style({transform: 'translateX(0)'})),
            ]),
            transition(':leave', [
                animate('500ms ease-out', style({transform: 'translateX(100%)'})),
            ])
        ]),

        trigger('slideOutButton', [
            transition(':enter', [
                style({transform: 'translateX(100%) translateY(-50%)'}),
                animate('300ms ease-out', style({transform: 'translateX(0) translateY(-50%)'}))
            ]),
            transition(':leave', [
                animate('300ms ease-in', style({transform: 'translateX(100%) translateY(-50%)'}))
            ])
        ])
    ],
    styles: [`
        @keyframes pulse {
            0% {
                transform: translateY(-50%) scale(1);
            }
            50% {
                transform: translateY(-50%) scale(1.05);
            }
            100% {
                transform: translateY(-50%) scale(1);
            }
        }
    `]
})
export class CartSidebarComponent extends OnDestroyPage{
    isCartOpen = false;
    isCustomProductModalOpen = false;
    cart: IEqipCartDocument;
    selectedCategory = 'all';
    filteredItems: OrderItem[] = [];
    categories: string[] = [];
    showNotes: { [key: string]: boolean } = {};
    private notesChange$ = new Subject<{ item: OrderItem, notes: string }>();

    constructor(private cartService: EqipCartService, private aSvc: AnalyticsService, private router: Router,private changeDetectorRef: ChangeDetectorRef) {
        super()
        this.cartService.cartDocument$
            .pipe(takeUntil(this.d$))
            .subscribe((cart) => {
            this.cart = cart;
            this.setCategories();
            this.filterItems();
        });
        this.setupNotesDebouncing();
    }

    toggleCart() {
        this.isCartOpen = !this.isCartOpen;
    }

    removeItem(item: OrderItem) {
        this.cartService.removeCartItem(this.cart, item);
        this.filterItems();
    }

    updateQuantity(item: OrderItem, newQuantity: number) {
        item.quantity = newQuantity;
        this.cartService.updateCartItem(this.cart, item);
    }

    toggleNotes(item: OrderItem) {
        this.showNotes[item.id] = !this.showNotes[item.id];
    }

    onNotesChange(item: OrderItem, notes: string) {
        this.notesChange$.next({item, notes});
    }

    setupNotesDebouncing() {
        this.notesChange$.pipe(debounceTime(1000),takeUntil(this.d$)).subscribe(({item, notes}) => {
            item.notes = notes;
            this.cartService.updateCartItem(this.cart, item).then(() => {
            }).catch(err => {
                console.error('Error saving notes:', err);
            });
        });
    }

    setCategories() {
        const allCategories = this.cart?.checkout.items.map(item => item.properties?.category);
        this.categories = Array.from(new Set(allCategories));
    }

    filterItems() {
        if (this.selectedCategory === 'all') {
            this.filteredItems = this.cart?.checkout.items;
        } else {
            this.filteredItems = this.cart?.checkout.items.filter(
                item => item.properties?.category === this.selectedCategory
            );
        }
    }
    async navigateToProduct(product:any) {

        await this.aSvc.track('ga', 'page_view', {
            name: product.name || '',
            url: product.url || '',
        });
        await this.router.navigate([product.url]);
    }

    trackByItemId(index: number, item: OrderItem): string {
        return item.id;
    }

    toggleCustomProductModal() {
        this.isCustomProductModalOpen = !this.isCustomProductModalOpen;
    }

    handleProductAdded(product: any) {
        this.setCategories();
        this.changeDetectorRef.detectChanges();
    }
}
