import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {ServiceWorkerModule} from '@angular/service-worker';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {eqipLazyComponents} from './_components/eqip.lazy.components';
import {sharedLazyComponents} from '@library/shared.lazy.components';

import {NotificationDialog} from '@library/shared/alert/notification.dialog';
import {getAnalytics, provideAnalytics,ScreenTrackingService} from '@angular/fire/analytics';
import {getApp, initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getAuth, provideAuth} from "@angular/fire/auth";

import {PageService} from '@library/shared/_services/page.service';
import {ClientService} from '@library/shared/_services/client.service';
import {LocalStorageService} from '@library/shared/_services/local-storage.service';
import {AnalyticsService} from '@library/shared/_services/analytics.service';
import {ThemeService} from '@library/shared/_services/theme.service';
import {ConsumerAnonAuthGuard} from '@library/consumer/_services/consumer.anon.auth.guard';
import {ConsumerAccountService} from "@library/consumer/_services/consumer.account.service";
import {EqipCartService} from "./services/eqip.cart.service";
import {ConsumerFireService} from "@library/consumer/_services/consumer.fire.service";

import {environment} from '@env/environment';

import {EquipmentPage} from "./public/equipment.page";
import {ProgressComponent} from "@library/shared/progress/progress.component";
import {equipmentPageConfigs} from "./config/configs";

import {AppComponent} from "./app.component";
import {PrivacyPolicyPage} from "./public/privacy-policy.page";
import {TermsOfUsePage} from "./public/terms-of-use.page";
import { EqipCustomLoaderComponent} from "./_components/custom-loader/custom-loader.component";
import {EqipTosHeaderComponent} from "./_components/tos-header/eqip-tos-header.component";
import {EqipClientGuard} from "./services/eqip-client.guard";


@NgModule({
    declarations: [AppComponent],
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: 'u',
                    loadChildren: () => import(`./u/u.module`).then(m => m.UserAreaModule),
                    canActivate: [EqipClientGuard]
                },
                {
                    path: 'camera',
                    component: EquipmentPage,
                    canActivate: [EqipClientGuard],
                    data: {config: equipmentPageConfigs.camera},
                },
                {
                    path: 'camera/:path',
                    component: EquipmentPage,
                    canActivate: [EqipClientGuard],
                    data: {config: equipmentPageConfigs.camera}
                },
                {
                    path: 'audio',
                    component: EquipmentPage,
                    canActivate: [EqipClientGuard],
                    data: {config: equipmentPageConfigs.audio},
                },
                {
                    path: 'audio/:path',
                    component: EquipmentPage,
                    canActivate: [EqipClientGuard],
                    data: {config: equipmentPageConfigs.audio}
                },
                {
                    path: 'mobile-truck-units',
                    component: EquipmentPage,
                    canActivate: [EqipClientGuard],
                    data: {config: equipmentPageConfigs.mobileTruckUnits},
                },
                {
                    path: 'mobile-truck-units/:path',
                    component: EquipmentPage,
                    canActivate: [EqipClientGuard],
                    data: {config: equipmentPageConfigs.mobileTruckUnits}
                },
                {
                    path: 'lighting',
                    component: EquipmentPage,
                    canActivate: [EqipClientGuard],
                    data: {config: equipmentPageConfigs.lighting},
                },
                {
                    path: 'lighting/:path',
                    component: EquipmentPage,
                    canActivate: [EqipClientGuard],
                    data: {config: equipmentPageConfigs.lighting}
                },
                {
                    path: 'privacy-policy',
                    component: PrivacyPolicyPage,
                    canActivate: [EqipClientGuard],
                },
                {
                    path: 'terms-of-use',
                    component: TermsOfUsePage,
                    canActivate: [EqipClientGuard],
                },
                {
                    path: '**',
                    loadChildren: () => import(`@library/shared/flex/flex.page`).then(m => m.FlexPageModule),
                    data: {header: true, footer: true},
                    canActivate: [EqipClientGuard]
                }
            ],
            {
                initialNavigation: 'enabledNonBlocking',
                scrollPositionRestoration: 'enabled'
            }
        ),
        BrowserModule, HttpClientModule, BrowserAnimationsModule,
        ServiceWorkerModule.register('/ngsw-worker.js', {
            enabled: true,
            registrationStrategy: 'registerWhenStable:6000'
        }), NotificationDialog,
        provideFirebaseApp(() => {
            return initializeApp(environment.firebase, environment.appName);
        }),
        provideAnalytics(() => {
            return getAnalytics(getApp(environment.appName));
        }),
        provideAuth(() => {
            return getAuth(getApp(environment.appName));
        }), ProgressComponent, EqipCustomLoaderComponent, EqipTosHeaderComponent,
    ],
    providers: [
        {provide: 'LazyComponents', useValue: [sharedLazyComponents, eqipLazyComponents]},
        {provide: 'MenuService', useValue: {getMenu: () => {}}},
        PageService, ClientService, LocalStorageService, AnalyticsService, ConsumerFireService,
        ThemeService, ConsumerAccountService,ConsumerAnonAuthGuard, EqipCartService,ScreenTrackingService,EqipClientGuard
    ],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
