"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrder = void 0;
const Base_1 = require("./Base");
const index_1 = require("../index");
class WorkOrder extends Base_1.Base {
    get name() {
        return this.toString();
    }
    constructor(data, olMap) {
        super('work_orders', 12);
        this.work_order_id = '';
        this.date = null;
        this.agent = null;
        this.agents = [];
        this.worker = null;
        this.workers = [];
        this.location = null;
        this.order = null;
        this.state = 'Draft';
        this.notes = '';
        this.items = [];
        this._m = ['name', 'items', 'agent', 'worker', 'location', 'order', 'work_order_id'];
        this._s = ['name', 'items', 'agent', 'worker', 'location', 'order', 'work_order_id', 'state'];
        (0, index_1.setObjectProperties)(this, data, olMap, WorkOrder);
    }
    toString() {
        var _a, _b;
        return `WorkOrder: ${this.work_order_id}: ${((_a = this.location) === null || _a === void 0 ? void 0 : _a.nickname) || ((_b = this.location) === null || _b === void 0 ? void 0 : _b.name) || ''}`;
    }
    async toSearchJSON(opts) {
        await this.loadAll(opts);
        let r = await super.toSearchJSON(opts);
        r.order = await this.order.toSearchJSON(opts);
        return r;
    }
}
exports.WorkOrder = WorkOrder;
index_1.olm.work_orders = (ref, map) => {
    return new WorkOrder(ref, map);
};
