import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {EqipCartService, IEqipCartDocument} from "../../../../services/eqip.cart.service";
import {getSID, OrderItem} from "@nxt/model-core";

@Component({
    selector: 'eqip-add-product-c',
    standalone: true,
    imports: [
        ReactiveFormsModule, CommonModule
    ],
    template: `
        <div class="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
            <div class="bg-white rounded-lg shadow-lg w-full max-w-md p-6 space-y-4">
                <h2 class="text-xl font-semibold text-gray-800">Add Custom Product</h2>
                <form [formGroup]="productForm" (ngSubmit)="submitForm()">
                    <div class="space-y-4">
                        <div>
                            <label class="block text-gray-700 font-medium">Name/Service:</label>
                            <input type="text" formControlName="name" placeholder="Product or Service Name"
                                   class="w-full mt-1 px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-accent-500"/>
                            <p class="text-xs text-gray-500 mt-1">
                                Note: If a product or service with the same name already exists in the cart, only the
                                quantity will be updated.
                            </p>
                        </div>

                        <div>
                            <label class="block text-gray-700 font-medium">Quantity:</label>
                            <input type="number" formControlName="quantity" min="1" placeholder="1"
                                   class="w-full mt-1 px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-accent-500"/>
                        </div>

                        <div>
                            <label class="block text-gray-700 font-medium">Category:</label>
                            <select formControlName="category"
                                    class="w-full mt-1 px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-accent-500">
                                <option value="" disabled>Select Category</option>
                                <option *ngFor="let category of categories" [value]="category">{{ category }}</option>
                            </select>
                        </div>

                        <div>
                            <label class="block text-gray-700 font-medium">Notes:</label>
                            <textarea formControlName="notes" rows="6" placeholder="Additional notes (optional)"
                                      class="w-full mt-1 px-4 py-2 border border-gray-300 rounded resize-none focus:outline-none focus:ring-2 focus:ring-accent-500"></textarea>
                        </div>
                    </div>
                    <div class="mt-6 flex justify-end space-x-4">
                        <button type="button" (click)="cancel()"
                                class="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400">
                            Cancel
                        </button>
                        <button type="submit" [disabled]="!productForm.valid"
                                class="bg-accent-600 text-white px-4 py-2 rounded hover:bg-accent-700 disabled:opacity-50">
                            Add Product
                        </button>
                    </div>
                </form>
            </div>
        </div>
    `
})

export class EqipAddProductC {
    @Output() productAdded = new EventEmitter<OrderItem>();
    @Output() closeModal = new EventEmitter<void>();

    productForm: FormGroup;
    cart: IEqipCartDocument;
    categories = ['Camera', 'Audio', 'Video', 'Electric', 'Grip', 'Production Supplies', 'Lighting', 'Mobile Truck Units', 'Event Services','Misc'];

    constructor(
        private fb: FormBuilder,
        private cartService: EqipCartService,
    ) {
        this.productForm = this.fb.group({
            name: ['', Validators.required],
            quantity: [1, [Validators.required, Validators.min(1)]],
            category: ['', Validators.required],
            notes: ['']
        });

        this.cartService.cartDocument$.subscribe(cart => {
            this.cart = cart;
        });
    }

    async submitForm() {
        if (this.productForm.valid) {
            const formData = this.productForm.value;
            const orderItem = new OrderItem({
                id:getSID(16),
                name: formData.name,
                quantity: formData.quantity,
                properties: {category: formData.category},
                notes: formData.notes,
            });

            const added = await this.cartService.addCartItem(this.cart, orderItem, false, true);
            if (added) {
                this.productAdded.emit(orderItem);
            }
            this.closeModal.emit();
        }
    }

    cancel() {
        this.closeModal.emit();
    }
}
