import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {ClientService} from '@library/shared/_services/client.service';
import {AnalyticsService} from '@library/shared/_services/analytics.service';
import {PageService} from '@library/shared/_services/page.service';
import {trigger, transition, style, animate} from '@angular/animations';
import {LocalStorageService} from "@library/shared/_services/local-storage.service";
import {SpinnerComponent} from "@library/shared/spinner/spinner.component";

@Component({
    selector: 'contact-chat-bubble',
    imports: [CommonModule, ReactiveFormsModule, IconsComponent, SpinnerComponent],
    template: `
        <div class="fixed bottom-6 right-4 z-[55]">
            <div *ngIf="showTimedMessage"
                 @fadeInOut
                 class="absolute -top-14 right-0 bg-accent-700 text-white py-2 px-2 sm:px-4 rounded shadow-lg font-bold text-xs sm:text-sm whitespace-nowrap flex items-center justify-between">
                <span>If you have any questions, please feel free to contact us!</span>
                <button class="ml-4 text-white hover:text-gray-300" (click)="closeMessage()">
                    <icon name="heroicon-outline-x" class="h-4 w-4"></icon>
                </button>
            </div>

            <!-- Contact Us label for chat bubble -->
            <div *ngIf="!showChatForm && showChatBubble" class="text-center mb-2 text-accent-600 font-semibold">
                Contact Us
            </div>

            <!-- Chat bubble for opening form -->
            <div *ngIf="!showChatForm && showChatBubble"
                 class="cursor-pointer bg-accent-700 text-white rounded-full p-3 sm:p-4 shadow-lg flex items-center justify-center w-12 h-12 sm:w-20 sm:h-20 sm:ml-0 ml-8"
                 (click)="toggleChat()">
                <icon name="heroicon-outline-chat" class="h-8 w-8 sm:h-12 sm:w-12"></icon>
            </div>

            <div *ngIf="showChatForm" @fadeInOutFast
                 class="bg-white rounded-lg shadow-lg p-6 w-96 sm:w-full max-w-xs sm:max-w-md">
                <form [formGroup]="chatForm" (ngSubmit)="submitChatForm()">
                    <h4 class="text-xl font-semibold mb-4 text-gray-900">Contact Us</h4>

                    <input formControlName="name" type="text" placeholder="Your Name"
                           class="w-full p-3 mb-1 border border-gray-300 rounded focus:outline-none focus:border-accent-600"/>
                    <div *ngIf="chatForm.get('name')?.touched && chatForm.get('name')?.invalid"
                         class="text-sm text-red-500 mb-2">
                        Name is required.
                    </div>

                    <input formControlName="email" type="email" placeholder="Email"
                           class="w-full p-3 mb-1 border border-gray-300 rounded focus:outline-none focus:border-accent-600"/>
                    <div *ngIf="chatForm.get('email')?.touched && chatForm.get('email')?.invalid"
                         class="text-sm text-red-500 mb-2">
                        <ng-container *ngIf="chatForm.get('email')?.errors?.['required']">
                            Email is required.
                        </ng-container>
                        <ng-container *ngIf="chatForm.get('email')?.errors?.['email']">
                            Please enter a valid email.
                        </ng-container>
                    </div>

                    <input formControlName="company" type="text" placeholder="Company (Optional)"
                           class="w-full p-3 mb-3 border border-gray-300 rounded focus:outline-none focus:border-accent-600"/>

                    <input formControlName="phone" type="tel" placeholder="Phone (Optional)"
                           class="w-full p-3 mb-3 border border-gray-300 rounded focus:outline-none focus:border-accent-600"/>

                    <textarea formControlName="notes" rows="3" placeholder="Your Message"
                              class="w-full p-2 mb-1 border border-gray-300 rounded focus:outline-none focus:border-accent-600"></textarea>
                    <div *ngIf="chatForm.get('notes')?.touched && chatForm.get('notes')?.invalid"
                         class="text-sm text-red-500 mb-2">
                        Message is required.
                    </div>

                    <div class="flex justify-between">
                        <button type="button" class="text-accent-600 font-semibold hover:underline"
                                (click)="cancelChat()">Cancel
                        </button>
                        <button type="submit" class="bg-accent-700 text-white px-4 py-2 rounded-lg">
                            <span *ngIf="!submitting" class="flex items-center">
                            Send Message
                        </span>
                            <span *ngIf="submitting" class="flex items-center">
                            <spinner class="mr-2 h-6 w-6 text-white"></spinner>
                            Sending...
                        </span>


                        </button>
                    </div>

                </form>
            </div>
        </div>
    `,
    animations: [
        trigger('fadeInOutFast', [
            transition(':enter', [
                style({opacity: 0, transform: 'translateY(10px)'}),
                animate('400ms ease-in-out', style({opacity: 1, transform: 'translateY(0)'}))
            ]),
            transition(':leave', [
                animate('300ms ease-in-out', style({opacity: 0, transform: 'translateY(10px)'}))
            ])
        ]),
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity: 0, transform: 'translateY(-10px)'}),
                animate('800ms ease-in-out', style({opacity: 1, transform: 'translateY(0)'}))
            ]),
            transition(':leave', [
                style({opacity: 0, transform: 'translateY(-10px)'})
            ])
        ])
    ],
    standalone: true,
})
export class ContactChatBubbleComponent implements OnInit, OnDestroy {
    chatForm: UntypedFormGroup;
    showChatForm: boolean = false;
    showChatBubble: boolean = true;
    signup: any = {email: '', name: '', notes: ''};
    submitting: boolean = false;
    showTimedMessage: boolean = false;
    private notificationSound: HTMLAudioElement;
    private userInteracted = false;
    private messageTimeoutId: any;

    constructor(
        private fb: UntypedFormBuilder,
        private aSvc: AnalyticsService,
        private cdr: ChangeDetectorRef,
        private cSvc: ClientService,
        private pSvc: PageService,
        private storage: LocalStorageService
    ) {
        this.chatForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            company: [''],
            phone: [''],
            notes: ['', Validators.required],
        });
        this.notificationSound = new Audio('/assets/sounds/pop.wav');
    }

    ngOnInit(): void {
        const lastSeen = this.storage.localState.lastSeen || 0;
        const now = Date.now();
        const sevenDays = 7 * 24 * 60 * 60 * 1000;

        if (now - lastSeen > sevenDays) {
            this.addClickListener();
        }
    }

    ngOnDestroy(): void {
        if (this.messageTimeoutId) {
            clearTimeout(this.messageTimeoutId);
        }

        document.removeEventListener('click', this.handleDocumentClick);
    }

    toggleChat() {
        this.showChatForm = !this.showChatForm;
        this.showChatBubble = !this.showChatForm;
    }

    cancelChat() {
        this.showTimedMessage = false;
        this.showChatForm = false;
        setTimeout(() => {
            this.showChatBubble = true;
        }, 350);
    }

    closeMessage() {
        this.showTimedMessage = false;
    }

    submitChatForm() {
        if (this.chatForm.valid && !this.submitting) {
            this.submitting = true;

            this.aSvc.track('ga', 'generate_lead', {event_label: 'chat_submit'});
            Object.assign(this.signup, this.chatForm.value);
            this.signup = this.aSvc.setReferrer(this.signup);

            this.cSvc
                .callAPI('/contact/signup', 'post', this.signup)
                .then(() => {
                    this.cancelChat();
                    this.pSvc.notification$.next({
                        title: 'Message Sent',
                        message: 'Your message has been sent. Please allow 1-2 buisness days for a reply. Thank you!',
                    });
                    this.submitting = false;
                    this.chatForm.reset();
                })
                .catch((error) => {
                    console.error('Error submitting chat form:', error);
                    this.submitting = false;

                    this.pSvc.notification$.next({
                        title: 'Message Failed',
                        message: 'An error occurred while sending your message. Please try again.',
                    });
                });

            this.cdr.detectChanges();
        } else {
            this.chatForm.markAllAsTouched();
        }
    }

    playNotificationSound(): void {
        this.notificationSound.play().catch((error) => {
            console.error('Error playing notification sounds:', error);
        });
    }

    private showMessage() {
        this.messageTimeoutId = setTimeout(() => {
            this.showTimedMessage = true;
            this.playNotificationSound();
            this.storage.saveState('lastSeen', Date.now());
        }, 10000);
    }

    private addClickListener(): void {
        document.addEventListener('click', this.handleDocumentClick.bind(this), {once: true});
    }

    private handleDocumentClick(): void {
        if (!this.userInteracted) {
            this.showMessage();
            this.userInteracted = true;
        }
    }
}

