import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {Router} from '@angular/router';
import {FormsModule} from "@angular/forms";
import {InputCounterComponent} from "../inputs/input-counter/eqip-input-counter.component";
import {OrderItem} from "@nxt/model-core";
import {EqipCartService, IEqipCartDocument} from "../../services/eqip.cart.service";
import {AnalyticsService} from "@library/shared/_services/analytics.service";
import {takeUntil} from "rxjs/operators";
import {OnDestroyPage} from "@library/shared/_inherited/ondestroy.page";

@Component({
    selector: 'app-search-results',
    imports: [CommonModule, FormsModule, InputCounterComponent],
    standalone: true,
    template: `
        <div [ngClass]="layoutClass">
            <div *ngFor="let result of searchResults; let i = index"
                 [ngClass]="isGridRowLayout ? 'card bg-white rounded-lg overflow-hidden flex flex-col'
                 : 'card bg-white rounded-lg flex mb-4 flex-col sm:flex-row overflow-hidden'"
                 [style.minWidth]="cardWidth"
                 [style.maxWidth]="cardWidth"
            >
                <img [ngClass]="isGridRowLayout ? 'w-full h-48 object-contain cursor-pointer' : 'w-full sm:w-1/3 h-48 object-contain cursor-pointer'"
                     (click)="navigateToProduct(result)"
                     [src]="result.image_urls?.length > 0 ? result.image_urls[0] : 'assets/camera_thumbnail.jpg'"
                     alt="product"
                     (error)="onImageError($event)">
                <div [ngClass]="isGridRowLayout ? 'p-3 text-center flex flex-col justify-between flex-grow' : 'flex flex-col justify-between p-3 w-full sm:w-2/3'">
                    <div  class="min-h-14 ">
                        <h3
                            class="text-lg font-medium cursor-pointer mb-1 line-clamp-2 whitespace-normal break-words"
                            (click)="navigateToProduct(result)">
                            {{ result.name }}
                        </h3>
                        <div *ngIf="result.properties?.category === 'Mobile Truck Units' || result.properties?.category ==='Electric'">
                            <span class="text-sm text-gray-500">{{ result.properties.location }}</span>
                        </div>
                        <a class="text-accent-600 cursor-pointer hover:underline text-sm" (click)="navigateToProduct(result)">Show Details</a>
                    </div>
                    <eqip-input-counter [(quantity)]="result.quantity" (quantityChange)="updateQuantity($event, i)"></eqip-input-counter>
                    <div class="flex w-full justify-center my-2">
                        <div class="border-t border-gray-200 w-full"></div>
                    </div>
                        <button class="add-item mt-2 border border-transparent bg-accent-700 text-white hover:bg-accent-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-lg px-4 py-2 text-center transition-colors duration-300 ease-in-out" (click)="addToCart(result, result.quantity)">Quick Add to Quote</button>
                </div>
            </div>
        </div>
    `,
    styles: [`
        @media (max-width: 1675px) {
            .custom-grid {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }
        }
        @media (min-width: 1675px) {
            .custom-grid {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }
        }
        .card {
            transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
        }
        .card:hover {
            transform: scale(1.03);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
        }
    `]
})
export class SearchResultsComponent extends OnDestroyPage implements OnInit {
    //takes in the results from the parent and display them
    @Input() searchResults: any[] = [];
    @Input() layout: 'grid' | 'list' | 'row' = 'grid';
    @Input() cardWidth: string = '';
    cart: IEqipCartDocument;

    constructor(private router: Router, private cartService: EqipCartService, private aSvc: AnalyticsService) {
        super()
    }
    get layoutClass() {
        switch (this.layout) {
            case 'grid':
                return 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 px-4 py-4';
            case 'list':
                return 'flex flex-col w-full px-4 py-4';
            case 'row':
                return 'flex flex-nowrap gap-6 px-4 py-4 overflow-x-auto no-scrollbar';
            default:
                return 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 px-4 py-4';
        }
    }

    ngOnInit() {
        // Subscribe to cartDocument$ to get the updated cart
        this.cartService.cartDocument$
            .pipe(takeUntil(this.d$))
            .subscribe(cart => {
            this.cart = cart;
        });
    }

    updateQuantity(newQuantity: number, index: number) {
        this.searchResults[index].quantity = newQuantity;
    }

    get isGridRowLayout() {
        return (this.layout === 'grid' || this.layout === 'row');
    }

    onImageError(event: any) {
        event.target.src = 'assets/camera_thumbnail.jpg';
    }

    async navigateToProduct(product:any) {
        await this.aSvc.track('ga', 'page_view', {
            name: product.name || '',
            url: product.url || '',
        });
        await this.router.navigate([product.url]);
    }

    async addToCart(result: any, quantity: number | undefined) {
        let item = new OrderItem({
            ...result,
            quantity: quantity === undefined ? 1 : quantity,
            product: {
                image_urls: result.image_urls ? result.image_urls[0] : ''
            }
        });
        await this.cartService.addCartItem(this.cart, item, false, true);
    }
}
