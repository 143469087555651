import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";

@Component({
    selector: 'eqip-custom-loader-component',
    standalone: true,
    styleUrls: ['./custom-loader.scss'],
    imports: [
        CommonModule
    ],
    template: `
        <div [ngClass]="['camera-loader', class]">
            <div class="camera-body">
                <div class="reel reel-large">
                    <div class="circle-container">
                        <div class="small-circle"></div>
                        <div class="small-circle"></div>
                        <div class="small-circle"></div>
                        <div class="small-circle"></div>
                    </div>
                </div>
                <div class="reel reel-small">
                    <div class="circle-container">
                        <div class="small-circle"></div>
                        <div class="small-circle"></div>
                        <div class="small-circle"></div>
                        <div class="small-circle"></div>
                    </div>
                </div>
                <div class="lens"></div>
                <div class="camera-base">
                    <div class="small-rectangle"></div>
                    <div class="red-circle"></div>
                    <div class="large-rectangle"></div>
                </div>
                <div class="leg leg-left"></div>
                <div class="leg leg-right"></div>
            </div>
        </div>
    `
})

export class EqipCustomLoaderComponent implements OnInit {
    @Input() block: boolean;
    @Input() class:string
    constructor() {
    }

    ngOnInit() {
    }
}
