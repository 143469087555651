import {Component, OnInit} from '@angular/core';
import {SharedService} from "../services/shared.service";
import {AsyncPipe} from "@angular/common";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {PageService} from "@library/shared/_services/page.service";
import {PolicyComponent} from "../_components/policy/policy.component";

@Component({
    selector: 'privacy-policy',
    standalone: true,
    imports: [
        PolicyComponent
    ],
    template: `
        <policy [class]="'mt-28 sm:px-10'" [documents]="document"></policy>
    `
})

export class PrivacyPolicyPage implements OnInit {
    document:string[]=['Privacy Policy']
    constructor(
        private pSvc: PageService,
    ) {}

    ngOnInit() {
        this.pSvc.titleService.setTitle(`Equipio - Privacy Policy`);
    }
}
