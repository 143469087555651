import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {IconsComponent} from "@library/shared/icons/icons.component";
import {AlgoliaService} from "../../services/algolia.service";
import {AnalyticsService} from "@library/shared/_services/analytics.service";
import {Router} from "@angular/router";

interface AlgoliaHit {
    objectID: string;
    name?: string;
    image_urls?: string[];
    url?: string;
}

@Component({
    selector: 'app-search-bar',
    standalone: true,
    template: `
        <div class="flex justify-center items-center h-12 w-full bg-white border border-gray-300 shadow-sm relative px-3">
            <input type="search"
                   class="flex-grow border-none bg-transparent text-gray-900 pl-3 pr-2 py-2 text-base focus:outline-none focus:ring-0 placeholder-gray-500"
                   [placeholder]="'Search ' + (category ? category + ' ' : '') + 'products...'"
                   [(ngModel)]="searchQuery"
                   (input)="onInput()"
                   (keyup.enter)="onSearch()"
                   autofocus
            />
            <button class="flex items-center justify-center w-10 h-10 bg-accent-700 text-white rounded-md disabled:bg-gray-300"
                    [disabled]="!searchQuery"
                    (click)="onSearch()">
                <icon name="heroicon-outline-search" class="w-5 h-5"></icon>
            </button>
            <ul *ngIf="suggestions.length > 0"
                class="absolute top-full left-0 right-0 bg-white border border-gray-300 rounded-lg mt-2 scrollbar shadow-lg z-10 max-h-56 overflow-auto py-2">
                <li *ngFor="let suggestion of suggestions"
                    (click)="navigateToProduct(suggestion)"
                    class="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center transition-colors duration-150 ease-in-out">
                    <img [src]="suggestion.imageUrl"
                         alt="product"
                         class="w-10 h-10 object-cover rounded-full mr-3"
                         (error)="onImageError($event)"/>
                    <span class="text-sm text-gray-700">{{ suggestion.name }}</span>
                </li>
            </ul>
        </div>

    `,
    imports: [
        FormsModule, CommonModule, IconsComponent
    ],
})
export class SearchBarComponent {
    searchQuery: string = '';
    suggestions: { name: string, imageUrl: string }[] = [];
    @Input() category: string = '';
    @Output() search = new EventEmitter<string>();

    constructor(private algoliaService: AlgoliaService, private aSvc: AnalyticsService, private router: Router) {
    }

    onSearch(): void {
        this.search.emit(this.searchQuery);
        this.suggestions = [];
    }

    onInput(): void {
        if (this.searchQuery.length > 1) {
            this.fetchSuggestions();
        } else if (this.searchQuery.length === 0) {
            this.suggestions = [];
            this.onSearch();
        }
    }

    fetchSuggestions(): void {
        this.algoliaService.search(this.searchQuery, {
            hitsPerPage: 8,
            filters: `properties.category:'${this.category}'`
        }).then(response => {
            this.suggestions = response.hits.map((hit: AlgoliaHit) => ({
                name: hit.name || '',
                imageUrl: hit.image_urls?.[0] || 'assets/default_thumbnail.jpg',
                url: hit.url || ''
            })).filter(suggestion => suggestion.name.trim().length > 0);
        }).catch(error => {
            console.error('Error fetching suggestions:', error);
            this.suggestions = [];
        });
    }

    async navigateToProduct(product: any) {
        await this.aSvc.track('ga', 'page_view', {
            name: product.name || '',
            url: product.url || '',
        });
        await this.router.navigate([product.url]);
    }

    onImageError(event: any) {
        event.target.src = 'assets/camera_thumbnail.png';
    }
}
