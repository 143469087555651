import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {ClientService} from '@library/shared/_services/client.service';
import {environment} from '@env/environment';
import {ConsumerFireService} from '@library/consumer/_services/consumer.fire.service';
import {ConsumerAccountService} from '@library/consumer/_services/consumer.account.service';
import {user as authUser} from '@angular/fire/auth';
import {User} from "@nxt/model-core";
import {firstValueFrom} from "rxjs";

@Injectable()
export class EqipClientGuard {
    constructor(
        private cSvc: ClientService,
        private fSvc: ConsumerFireService,
        private aSvc: ConsumerAccountService
    ) {

    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        return new Promise(async (resolve) => {
            this.cSvc.c$.next(environment.default_client);
            try {
                const user = await firstValueFrom(authUser(this.aSvc.auth));
                if (!user) {
                    await this.aSvc.signInAnon();
                }
                resolve(true);
            } catch (err) {
                console.warn(err);
                resolve(false);
            }
        });
    }
}
