import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    HeroSectionComponent
} from "../_components/otherpage-sections/eqip-hero-horizontal/eqip-hero-horizontal.component";
import {EqipCtaImagesComponent} from "../_components/otherpage-sections/eqip-cta-images/eqip-cta-images.component";
import {EqipReviewSection} from "../_components/otherpage-sections/eqip-review-section/eqip-review-section.component";
import {
    EqipGridFeatureSectionComponent
} from "../_components/otherpage-sections/eqip-grid-feature-section/eqip-grid-feature-section.component";

@Component({
    selector: 'playground',
    standalone: true,
    imports: [
        CommonModule,
        HeroSectionComponent,
        EqipCtaImagesComponent,
        EqipReviewSection,
        EqipGridFeatureSectionComponent,
    ],
    template: `
<!--    <eqip-hero-horizontal></eqip-hero-horizontal>-->
<!--    <eqip-cta-images></eqip-cta-images>-->
<!--    <eqip-review-section></eqip-review-section>-->
<!--    <eqip-grid-feature-section></eqip-grid-feature-section>-->
    `
})
export class PlaygroundPage {


    constructor() {

    }

}
