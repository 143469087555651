"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EqipContact = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class EqipContact extends model_core_1.Contact {
    constructor(data, olMap, type, idLen) {
        super(data, olMap || index_1.olm);
        this.billingAddress = null;
        this.yearEstablished = null;
        this.stateIncorporated = null;
        this.yearIncorporated = null;
        this.typeOfEntity = '';
        this.dnbNumber = '';
        this.salesContact = {};
        this.tradeReferences = [];
        this.insuranceInformation = null;
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, model_core_1.Contact);
    }
}
exports.EqipContact = EqipContact;
index_1.olm.contacts = (ref, map) => {
    return new EqipContact(ref, map);
};
