import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ClientService} from '@library/shared/_services/client.service';
import {SharedService} from '../../services/shared.service';
import {Router, RouterLink} from '@angular/router';
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {Contact} from "@nxt/model-core";
import {delay, takeUntil} from "rxjs/operators";
import {OnDestroyPage} from "@library/shared/_inherited/ondestroy.page";
import {PageService} from "@library/shared/_services/page.service";

export interface Agreement {
    agreement_name: string;
    type?: string;
    version: string;
    date: number;
    agreement_text: string;
    sanitizedText?: string | any;
}

export interface DocumentType {
    type: string;
    documents: string[];
    message:string;
}

@Component({
    selector: 'eqip-tos-header',
    imports: [CommonModule, FormsModule, RouterLink],
    standalone: true,
    template: `
        <div *ngIf="showTerms && !isExcludedRoute" class="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-[55]">
            <div class="bg-white p-8 rounded-lg shadow-2xl w-11/12 md:w-2/3 lg:w-1/3 max-w-lg">
                <div class="text-center">
                    <h2 class="text-2xl font-semibold text-gray-800 mb-6">
                        Review and Agree to Updated Terms
                    </h2>
                    <p class="text-gray-500 mb-6">
                        Please agree to the following updated terms before continuing:
                    </p>
                </div>
                <form (ngSubmit)="agreeToTerms()" class="space-y-4">
                    <div *ngFor="let agreement of agreementsToUpdate" class="flex items-start space-x-3">
                        <input
                            type="checkbox"
                            name="agreement_{{agreement.agreement_name}}"
                            class="h-5 w-5 text-accent-600 border-gray-300 rounded focus:ring-accent-500"
                            [(ngModel)]="agreedAgreements[agreement.agreement_name]" required
                        />

                        <label class="text-gray-800">
                            <ng-container *ngIf="isLink(agreement.agreement_name); else plainText">
                                <span>I agree to </span>
                                <a [routerLink]="getAgreementLink(agreement.agreement_name)" class="underline text-accent-600 hover:text-accent-800">
                                    {{ agreement.agreement_name }}
                                </a>
                            </ng-container>

                            <ng-template #plainText>
                                <span>
                                    I agree to
                                    <ng-container *ngIf="agreement.message; else defaultText">
                                        {{ agreement.message }}
                                    </ng-container>
                                    <ng-template #defaultText>{{ agreement.agreement_name }} (v{{ agreement.version }})</ng-template>
                                </span>
                            </ng-template>
                        </label>
                    </div>

                    <div class="text-center mt-6">
                        <button
                            type="submit"
                            [disabled]="!isAllAgreementsChecked()"
                            class="w-full bg-accent-700 bg-accent-800 text-white font-bold py-3 rounded-lg shadow-lg disabled:bg-gray-400 disabled:cursor-not-allowed transition duration-300 ease-in-out"
                        >
                            Agree and Continue
                        </button>
                    </div>
                </form>
            </div>
        </div>
    `
})
export class EqipTosHeaderComponent extends OnDestroyPage implements OnInit {
    @Input() documentTypes: DocumentType[] = [];
    showTerms: boolean = false;
    agreedAgreements: { [agreementName: string]: boolean } = {};
    agreements: Agreement[] = [];
    agreementsToUpdate: Array<Agreement & { message?: string }> = [];

    contact: Contact;
    excludedRoutes: string[] = ['/privacy-policy', '/terms-of-use', '/', '/contact-us', '/about-us','/u/account/support','u/account/profile'];
    isExcludedRoute: boolean = false;

    constructor(
        private cSvc: ClientService,
        private pSvc: PageService,
        private sharedSvc: SharedService,
        private router: Router
    ) {
        super();
    }

    async ngOnInit() {
        this.router.events.subscribe(() => {
            this.checkIfExcludedRoute();
        });
        this.cSvc.u$
            .pipe(delay(3000), takeUntil(this.d$))
            .subscribe(async (user) => {
                if (user && !user?.isAnonymous) {
                    this.contact = await this.sharedSvc.fetchContact();
                if (this.contact) {
                    const documents = ['Privacy Policy', 'Terms of Use'];
                    const matchingType = this.documentTypes.find(docType => docType.type === this.contact.type);
                    if (matchingType) {
                        documents.push(...matchingType.documents);
                    }
                    const data = {
                        documents,
                        type: this.contact.type,
                        minimal: true
                    };
                    this.agreements = await this.cSvc.callAPI('/client/getAgreements', 'post', data);
                    this.checkAgreements();
                }
            }
        });
    }

    checkIfExcludedRoute() {
        const currentRoute = this.router.url;
        this.isExcludedRoute = this.excludedRoutes.includes(currentRoute);
    }

    checkAgreements() {
        const contactAgreements = this.contact?.metadata?.agreements || [];
        this.agreementsToUpdate = [];

        this.agreements.forEach(agreement => {
            const contactAgreement = contactAgreements.find(a => a.agreement_name === agreement.agreement_name);
            if (!contactAgreement || contactAgreement.version !== agreement.version) {
                const message = this.getDocumentMessage(agreement.agreement_name, this.contact.type);
                this.agreementsToUpdate.push({ ...agreement, message });
            }
        });

        this.showTerms = this.agreementsToUpdate.length > 0;
    }

    isAllAgreementsChecked(): boolean {
        return this.agreementsToUpdate.every(agreement => this.agreedAgreements[agreement.agreement_name]);
    }

    isLink(agreementName: string): boolean {
        return agreementName === 'Privacy Policy' || agreementName === 'Terms of Use';
    }

    getAgreementLink(agreementName: string): string {
        if (agreementName === 'Privacy Policy') {
            return '/privacy-policy';
        } else if (agreementName === 'Terms of Use') {
            return '/terms-of-use';
        }
        return '';
    }

    getDocumentMessage(agreementName: string, agreementType: string): string | null {
        const matchingDocument = this.documentTypes.find(
            docType => docType.documents.includes(agreementName) && docType.type === agreementType
        );
        return matchingDocument ? matchingDocument.message : null;
    }

    async agreeToTerms() {
        if (this.isAllAgreementsChecked()) {
            this.contact.metadata.agreements = this.agreements.map(agreement => ({
                agreement_name: agreement.agreement_name,
                version: agreement.version,
                date: Date.now()
            }));

            try {
                this.cSvc.callAPI('/contact/updateContact', 'post', {
                    userInfo: this.contact
                });
                this.pSvc.notification$.next({
                    title: 'Success',
                    message: `Your agreements have been successfully updated. Thank you for your cooperation.`,
                });
                this.showTerms = false;
            } catch (error) {
                console.error('Error updating contact agreements:', error);
            }
        }
    }
}


