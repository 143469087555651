import { Directive, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[appFooterOffScreen]'
})
export class FooterOffScreenDirective implements AfterViewInit {

    constructor(private renderer: Renderer2) {}

    ngAfterViewInit() {
        const elements = document.querySelectorAll('[appFooterOffScreen]');

        elements.forEach((element: Element) => {
            this.renderer.addClass(element, 'mt-[100vh]');
        });

        setTimeout(() => {
            elements.forEach((element: Element) => {
                this.renderer.removeClass(element, 'mt-[100vh]');
            });
        }, 1000);
    }
}
