import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';

interface FacetData {
    value: string;
    count: number;
}

@Component({
    selector: 'app-facet-list',
    standalone: true,
    imports: [CommonModule],
    template: `
        <div class="relative bg-white shadow-md rounded-lg overflow-hidden mt-2" *ngIf="displayedFacets.length > 0">
            <h2 *ngIf="heading"
                class="text-lg font-semibold text-gray-700 bg-gray-200 px-6 py-3 flex justify-between items-center cursor-pointer"
                (click)="toggleFilter()">
                {{ heading }}
                <svg [ngClass]="{'rotate-180': !isFilterVisible}" class="w-4 h-4 transition-transform"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                </svg>
            </h2>
            <div [class.hidden]="!isFilterVisible" class="py-2">
                <ul class="list-none p-0">
                    <li *ngFor="let facet of displayedFacets" class="mt-2 px-6">
                        <label class="checkbox-wrapper flex items-center cursor-pointer">
                            <input type="checkbox" class="sr-only" [value]="facet.value"
                                   [checked]="isActiveFacet(facet.value)" (change)="onFacetChange(facet.value, $event)">
                            <span class="checkbox-custom"></span>
                            <span class="ml-2 text-base text-gray-600">{{ facet.value }} ({{ facet.count }})</span>
                        </label>
                    </li>
                </ul>
                <button *ngIf="facets.length > initialFacetCount"
                        class="mt-2 ml-2 px-4 py-2 text-accent-600 hover:text-accent-700 text-sm font-semibold rounded focus:outline-none focus:ring-2 focus:ring-accent-600 focus:ring-opacity-50 transition-colors duration-200"
                        (click)="toggleShowMore()">
                    {{ showAll ? 'Show Less' : 'Show More' }}
                </button>
            </div>
        </div>
    `,
    styles: [`
        .checkbox-wrapper {
            @apply flex items-center;
        }

        .checkbox-custom {
            display: inline-block;
            width: 18px;
            height: 18px;
            background-color: #fff;
            border: 2px solid #ccc;
            border-radius: 4px;
            position: relative;
            cursor: pointer;
        }

        .checkbox-custom::before {
            content: '';
            position: absolute;
            display: none;
            left: 6px;
            top: 2.25px;
            width: 5px;
            height: 9px;
            border-style: solid;
            border-color: #000;
            border-width: 0 2px 2px 0;
            transform: rotate(43deg);
        }


        .checkbox-label input[type="checkbox"]:checked ~ .checkbox-custom {
            @apply bg-black border-black;
        }

        input[type="checkbox"]:checked + .checkbox-custom::before {
    display: block;
}
    `]
})
export class FacetListComponent implements OnInit, OnChanges {
    //facet values. Ex. Brands: [sony,canon,fujinon]. Also just added a count for each value
    @Input() facets: FacetData[] = [];
    @Input() initialFacetCount = 5;
    // Boolean toggle to show the "show more" text under the filter or not
    @Input() showToggle = true;
    //This so it only list the active facets
    @Input() activeFacets: { [key: string]: string[] } = {};
    //This is the facet attribute property we are targeting. Like brand or resolution. This is listed in Algolia.
    @Input() facetAttribute: string;
    @Output() facetSelected = new EventEmitter<{facet: string, isSelected: boolean}>();
    //Whether to have a toggle and limit how many facet's to display. Some categories like Brand could be huge.
    showAll = false;
    //toggle for chevron
    isFilterVisible = true;
    displayedFacets: { value: string; count: number }[] = [];
    //New input for the heading
    @Input() heading: string;
    ngOnInit(): void {
        if (this.facetAttribute === 'properties.brand') {
            this.initialFacetCount = 10;
        }
        this.updateDisplayedFacets();
        console.log('i am facets',this.facets,this.activeFacets)
    }
    //ngOnChanges responds to changes on the input property. It takes in SimpleChanges which contains the current and previous property values
    //basically in this context if facets array changes then it will trigger this.updateDisplayedFacets
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['facets']) {
            this.updateDisplayedFacets();
        }
    }
    //emit to the parent the facet selected and the boolean value. This happens on the change
    onFacetChange(facet: string, event: Event): void {
        const input = event.target as HTMLInputElement;
        this.facetSelected.emit({ facet, isSelected: input.checked });
    }
    //This creates the drop down effect for the toggles. So when you click show more. More show up
    toggleShowMore(): void {
        this.showAll = !this.showAll;
        this.updateDisplayedFacets();
    }
    //Basically whether this checkbox is checked or unchecked.
    isActiveFacet(facet: string): boolean {
        return this.activeFacets[this.facetAttribute]?.includes(facet);
    }
    //function for chevron flip-flop
    toggleFilter(): void {
        this.isFilterVisible = !this.isFilterVisible;
    }
    //OnInit function that if ShowAll is set to true will only show facet values up to the initial facet count
    //Changed this to also be sorted
    private updateDisplayedFacets(): void {
        this.displayedFacets = this.facets.slice().sort((a, b) => {
            if (!isNaN(Number(a.value)) && !isNaN(Number(b.value))) {
                return Number(a.value) - Number(b.value);
            }

            return a.value.localeCompare(b.value);
        });

        if (!this.showAll) {
            this.displayedFacets = this.displayedFacets.slice(0, this.initialFacetCount);
        }
    }
}
