import {ChangeDetectorRef, Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import { CommonModule } from "@angular/common";
import { IconsComponent } from "@library/shared/icons/icons.component";
import { Router, RouterModule } from '@angular/router';

import { PageService } from "../../../../../_library/shared/_services/page.service";

@Component({
    selector: 'eqip-nav-dropdown',
    standalone: true,
    imports: [IconsComponent, CommonModule, RouterModule],
    template: `
        <div class="relative">
            <nav (mouseleave)="mouseLeft.emit()"
                 class="absolute left-0 top-full mt-2 bg-white rounded-xl border border-gray-300 shadow-xl z-50 max-w-[95vw] xl:w-[780px] text-sm">
                <section class="flex flex-col lg:flex-row p-1.5 sm:p-2.5 lg:p-3 gap-2 lg:gap-3">
                    <div class="w-full lg:flex-1 lg:min-w-48 lg:mr-2">
                        <div
                            *ngFor="let item of items; let i = index"
                            class="flex gap-2 sm:gap-2.5 lg:gap-3 items-start p-1.5 sm:p-2 rounded-md cursor-pointer transition-all duration-200"
                            [ngClass]="{
                                  'bg-gray-100 scale-[1.015] lg:scale-105 shadow-sm': activeItemIndex === i && !item.disable,
                                  'pointer-events-none cursor-not-allowed': item.disable
                              }"
                            (mouseenter)="!isMobile && !item.disable && setActiveItem(i)"
                            (click)="!item.disable && handleItemClick(i, item.url, item.label)">
                            <img [src]="item.image"
                                 [ngClass]="{'grayscale': item.disable}"
                                 class="mt-0.5 w-3.5 h-3.5 sm:w-4 sm:h-4 lg:w-5 lg:h-5 aspect-square rounded shadow-sm"
                                 alt=""/>
                            <div class="flex-1">
                                <h3 class="text-xs sm:text-sm lg:text-base font-semibold leading-tight"
                                    [ngClass]="{ 'text-gray-400': item.disable, 'text-gray-900': !item.disable }">
                                    {{ item.label }}
                                </h3>
                                <p class="text-[10px] sm:text-xs lg:text-sm font-light leading-snug"
                                   [ngClass]="{ 'text-gray-400': item.disable, 'text-gray-600': !item.disable }">
                                    {{ item.description }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <aside *ngIf="!isMobile" class="w-full lg:min-w-48 lg:w-[360px] px-2 py-2 sm:px-2.5 sm:py-2.5 bg-gray-100 border-t lg:border-t-0 lg:border-l border-gray-300 rounded-md lg:rounded-none lg:ml-2">
                        <article class="text-left">
                            <a [routerLink]="items[activeItemIndex]?.url">
                                <img [src]="items[activeItemIndex]?.asideImage"
                                     class="w-[60%] sm:w-[75%] lg:w-[70%] sm:mx-auto lg:mx-0 rounded-lg shadow-md"
                                     alt=""/>
                            </a>
                            <div class="mt-2 sm:mt-2.5">
                                <h3 class="text-sm sm:text-base font-semibold text-gray-900">
                                    {{ items[activeItemIndex]?.asideTitle }}
                                </h3>
                                <p class="text-xs sm:text-sm text-gray-600 mt-0.5">
                                    {{ items[activeItemIndex]?.asideText }}
                                </p>
                                <div class="flex justify-start gap-2 mt-2">
                                    <a [routerLink]="items[activeItemIndex]?.url"
                                       class="text-accent-700 text-xs sm:text-sm hover:text-accent-600"
                                       (click)="handleItemClick(activeItemIndex, items[activeItemIndex]?.url, items[activeItemIndex]?.label)">
                                    Explore</a>
                                </div>
                            </div>
                        </article>
                    </aside>
                </section>
            </nav>
        </div>
    `
})
export class EqipNavDropdownComponent {
    @Output() mouseLeft = new EventEmitter<void>();
    @Output() itemSelected = new EventEmitter<string>();
    @Input() items:any;
    isMenuOpen = false;
    isMobile = window.innerWidth < 640;
    activeItemIndex = 0;

    constructor(
      public pSvc: PageService,
      private router: Router,
    ) {}

    @HostListener("document:click", ["$event"])
    clickOutside(event: Event) {
        const element = event.target as HTMLElement;
        if (!element.closest("eqip-nav-dropdown")) {
            this.isMenuOpen = false;
        }
    }

    @HostListener('window:resize')
    onResize() {
        this.isMobile = window.innerWidth < 640;
    }

    setActiveItem(index: number): void {
        this.activeItemIndex = index;
    }

    handleItemClick(index: number, url: string, label: string) {
        this.setActiveItem(index);
        this.itemSelected.emit(label);
        this.isMenuOpen = false;
        this.router.navigateByUrl(url);
    }
}
