"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ENxtCronTasks = exports.ECoreCronTasks = exports.ECoreCronManualTasks = exports.getDistanceBetweenAddresses = exports.ChildArray = exports.cleanUrl = exports.toMiniJSON = exports.toFullJSON = exports.toMinJSON = exports.toJSON = exports.loadAll = exports.loadObject = exports.getDelta = exports.Exception = exports.olm = void 0;
const Dimension_1 = require("./src/Dimension");
const operators_1 = require("rxjs/operators");
const Common_1 = require("./src/Common");
exports.olm = {};
__exportStar(require("./src/Ad"), exports);
__exportStar(require("./src/Address"), exports);
__exportStar(require("./src/Base"), exports);
__exportStar(require("./src/AlgoliaSearch"), exports);
__exportStar(require("./src/Client"), exports);
__exportStar(require("./src/CloudFile"), exports);
__exportStar(require("./src/CmsSettings"), exports);
__exportStar(require("./src/Common"), exports);
__exportStar(require("./src/Contact"), exports);
__exportStar(require("./src/CreditCard"), exports);
__exportStar(require("./src/Deployment"), exports);
__exportStar(require("./src/Dimension"), exports);
__exportStar(require("./src/Discount"), exports);
__exportStar(require("./src/Document"), exports);
__exportStar(require("./src/EasyPost"), exports);
__exportStar(require("./src/Filter"), exports);
__exportStar(require("./src/Fraction"), exports);
__exportStar(require("./src/Group"), exports);
__exportStar(require("./src/Metric"), exports);
__exportStar(require("./src/Order"), exports);
__exportStar(require("./src/OrderItem"), exports);
__exportStar(require("./src/Packaging"), exports);
__exportStar(require("./src/Page"), exports);
__exportStar(require("./src/Payment"), exports);
__exportStar(require("./src/Pricing"), exports);
__exportStar(require("./src/Product"), exports);
__exportStar(require("./src/Shipment"), exports);
__exportStar(require("./src/StripeCharge"), exports);
__exportStar(require("./src/Thread"), exports);
__exportStar(require("./src/ThreadMessage"), exports);
__exportStar(require("./src/Token"), exports);
__exportStar(require("./src/Tour"), exports);
__exportStar(require("./src/Unread"), exports);
__exportStar(require("./src/User"), exports);
__exportStar(require("./src/Device"), exports);
__exportStar(require("./src/Role"), exports);
__exportStar(require("./src/QueueItem"), exports);
__exportStar(require("./src/Feedback"), exports);
__exportStar(require("./src/Task"), exports);
__exportStar(require("./src/WorkOrder"), exports);
class Exception {
    constructor(title, message, handled) {
        this.title = title;
        this.message = message;
        this.handled = handled;
    }
    toString() {
        return `Exception: ${this.title}: ${this.message}`;
    }
}
exports.Exception = Exception;
const getDelta = (newData, oldData, level, ignoreList) => {
    let delta = {};
    level = level || 0;
    if (newData && Object.keys(newData) && oldData) {
        Object.keys(newData).forEach(prop => {
            if (!prop.match(/^_/) && (!ignoreList || ignoreList.indexOf(prop) === -1)) {
                if (typeof newData[prop] === 'object' && level < 2) {
                    let subdelta = (0, exports.getDelta)(newData[prop], oldData[prop], level + 1);
                    if (subdelta && Object.keys(subdelta) && Object.keys(subdelta).length) {
                        // delta[prop] = subdelta;
                        delta[prop] = newData[prop];
                    }
                }
                else if (newData[prop] !== oldData[prop]) {
                    if (typeof newData[prop] !== 'object' || (newData[prop] && Object.keys(newData[prop]) && Object.keys(newData[prop]).length)) {
                        delta[prop] = newData[prop];
                    }
                }
            }
        });
    }
    else if (newData) {
        delta = newData;
    }
    return delta;
};
exports.getDelta = getDelta;
function loadObject(o, opts) {
    var _a, _b, _c, _d;
    // if (!opts.olm) {
    //     console.warn( `Using default olm on ${o._type} ...`);
    // }
    opts.olm = opts.olm || exports.olm;
    try {
        let type;
        if (o === null || o === void 0 ? void 0 : o._type) {
            type = o === null || o === void 0 ? void 0 : o._type;
        }
        else if (o) {
            if ((_a = o.payload) === null || _a === void 0 ? void 0 : _a.doc) {
                o = o.payload.doc;
            }
            else if ((_b = o.doc) === null || _b === void 0 ? void 0 : _b.exists) {
                o = o.doc;
            }
            if (((_c = o === null || o === void 0 ? void 0 : o.ref) === null || _c === void 0 ? void 0 : _c.path) || (o === null || o === void 0 ? void 0 : o.path)) {
                let path = ((_d = o === null || o === void 0 ? void 0 : o.ref) === null || _d === void 0 ? void 0 : _d.path) || (o === null || o === void 0 ? void 0 : o.path);
                type = path === null || path === void 0 ? void 0 : path.split('/')[(path === null || path === void 0 ? void 0 : path.split('/').length) - 2];
            }
        }
        if (!o) {
            return o;
        }
        else {
            if (type && opts.olm[type]) {
                return opts.olm[type](o, opts.olm);
            }
            else if (type) {
                if (type === 'events' && o.data) {
                    return o.data();
                }
                else {
                    console.warn(`No object loader for type ${type}`, o);
                }
            }
        }
    }
    catch (e) {
        console.warn(e, o);
    }
}
exports.loadObject = loadObject;
let isUser = (path) => {
    return path === null || path === void 0 ? void 0 : path.match(/^users\/([^\/]*)$/);
};
async function loadAll(parent, opts, stack, p) {
    var _a, _b;
    if (opts === null || opts === void 0 ? void 0 : opts.firestore) {
        return loadAllAngular(parent, opts, stack, p);
    }
    else {
        opts = opts || {};
        opts.olm = opts.olm || exports.olm;
        if ((_a = parent === null || parent === void 0 ? void 0 : parent._docRef) === null || _a === void 0 ? void 0 : _a.path) {
            stack = stack || [];
            stack.push((_b = parent === null || parent === void 0 ? void 0 : parent._docRef) === null || _b === void 0 ? void 0 : _b.path);
            // Load a prototype version of this object so we can check for ChildArray types.
            let props = (opts.properties !== undefined) ? opts.properties : Object.getOwnPropertyNames(parent);
            await Promise.all(props.map(async (p) => {
                var _a, _b, _c, _d;
                if (parent[p]) {
                    if ((_a = parent[p]._docRef) === null || _a === void 0 ? void 0 : _a.path) {
                        try {
                            if (((_b = parent[p]._docRef) === null || _b === void 0 ? void 0 : _b.path) && !isUser((_c = parent === null || parent === void 0 ? void 0 : parent._docRef) === null || _c === void 0 ? void 0 : _c.path)) {
                                parent[p] = loadObject(await parent[p]._docRef.get(), opts);
                                let o = Object.assign({}, opts);
                                if (!stack.includes((_d = parent[p]._docRef) === null || _d === void 0 ? void 0 : _d.path)) {
                                    let copy = Object.assign([], stack);
                                    await parent[p].loadAll(o, copy, p);
                                }
                            }
                        }
                        catch (e) {
                            if (e.toString().match(/code=permission-denied/)) {
                                let m = e.toString().match(/Property (?<role>\S+) is undefined on object./);
                                //console.warn(`PROP (1) ${p}: ${m?.groups?.role||'?'}: ${parent[p]?._docRef?.path||'no path'}`);
                            }
                            else {
                                //console.warn(`PROP (1) ${p}: ${e.toString()}: ${parent[p]?._docRef?.path||'no path'}`);
                            }
                        }
                    }
                    else if (parent['_p'][p] instanceof ChildArray || parent[p] instanceof ChildArray) {
                        try {
                            let q = await parent._docRef.collection(p).get();
                            if (q === null || q === void 0 ? void 0 : q.size) {
                                parent[p] = await Promise.all(q.docs.map(async (d) => {
                                    var _a, _b;
                                    let i = loadObject(d, opts);
                                    if ((_a = i === null || i === void 0 ? void 0 : i._docRef) === null || _a === void 0 ? void 0 : _a.path) {
                                        let o = Object.assign({}, opts);
                                        if (!stack.includes((_b = i._docRef) === null || _b === void 0 ? void 0 : _b.path)) {
                                            let copy = Object.assign([], stack);
                                            await i.loadAll(o, copy, p);
                                        }
                                    }
                                    return i;
                                }));
                            }
                            else {
                                parent[p] = [];
                            }
                        }
                        catch (e) {
                            if (e.toString().match(/code=permission-denied/)) {
                                let m = e.toString().match(/Property (?<role>\S+) is undefined on object./);
                                //console.warn(`CHILD ${p}: ${m?.groups?.role||'?'}`);
                            }
                            else {
                                //console.warn(`CHILD ${p}: ${e.toString()}`, parent[p]._docRef?.path);
                            }
                        }
                    }
                    else if (parent[p] instanceof Array && parent[p].length && !(parent[p] instanceof Dimension_1.DimensionArray)) {
                        parent[p] = await Promise.all(parent[p].map(async (item) => {
                            var _a, _b, _c;
                            try {
                                if (((_a = item === null || item === void 0 ? void 0 : item._docRef) === null || _a === void 0 ? void 0 : _a.path) && !isUser((_b = item === null || item === void 0 ? void 0 : item._docRef) === null || _b === void 0 ? void 0 : _b.path)) {
                                    item = loadObject(await item._docRef.get(), opts);
                                    let o = Object.assign({}, opts);
                                    if (!stack.includes((_c = item._docRef) === null || _c === void 0 ? void 0 : _c.path)) {
                                        let copy = Object.assign([], stack);
                                        await item.loadAll(o, copy, p);
                                    }
                                }
                            }
                            catch (e) {
                                if (e.toString().match(/code=permission-denied/)) {
                                    let m = e.toString().match(/Property (?<role>\S+) is undefined on object./);
                                    //console.warn(`PROP (2) ${p}: ${m?.groups?.role||'?'}`);
                                }
                                else {
                                    //console.warn(`PROP (2) ${p}: ${e.toString()}`, parent[p]._docRef?.path);
                                }
                            }
                            return item;
                        }));
                    }
                }
            }));
        }
    }
}
exports.loadAll = loadAll;
async function loadAllAngular(parent, opts, stack, p) {
    var _a, _b;
    opts = opts || {};
    opts.olm = opts.olm || exports.olm;
    if ((_a = parent === null || parent === void 0 ? void 0 : parent._docRef) === null || _a === void 0 ? void 0 : _a.path) {
        stack = stack || [];
        stack.push((_b = parent === null || parent === void 0 ? void 0 : parent._docRef) === null || _b === void 0 ? void 0 : _b.path);
        // Load a prototype version of this object so we can check for ChildArray types.
        let props = (opts.properties !== undefined) ? opts.properties : Object.getOwnPropertyNames(parent);
        await Promise.all(props.map(async (p) => {
            var _a, _b, _c, _d, _e;
            if (parent[p]) {
                if ((_a = parent[p]._docRef) === null || _a === void 0 ? void 0 : _a.path) {
                    try {
                        if (((_b = parent[p]._docRef) === null || _b === void 0 ? void 0 : _b.path) && !isUser((_d = (_c = parent[p]) === null || _c === void 0 ? void 0 : _c._docRef) === null || _d === void 0 ? void 0 : _d.path)) {
                            parent[p] = loadObject(opts.getDoc ? await opts.getDoc(parent[p]._docRef) : await parent[p]._docRef.get(), opts);
                            let o = Object.assign({}, opts);
                            if (!stack.includes((_e = parent[p]._docRef) === null || _e === void 0 ? void 0 : _e.path)) {
                                let copy = Object.assign([], stack);
                                await parent[p].loadAll(o, copy, p);
                            }
                        }
                    }
                    catch (e) {
                        if (e.toString().match(/code=permission-denied/)) {
                            let m = e.toString().match(/Property (?<role>\S+) is undefined on object./);
                            //console.warn(`PROP (1) ${p}: ${m?.groups?.role||'?'}: ${parent[p]?._docRef?.path||'no path'}`);
                        }
                        else {
                            //console.warn(`PROP (1) ${p}: ${e.toString()}: ${parent[p]?._docRef?.path||'no path'}`);
                        }
                    }
                }
                else if (parent['_p'][p] instanceof ChildArray) {
                    if (!opts.collection || !opts.collectionSnapshots) {
                        throw `Cannot load sub-collection without opts.collection/collectionSnapshots.`;
                    }
                    if (!opts.firestore) {
                        throw `Cannot load sub-collection without opts.firestore.`;
                    }
                    try {
                        try {
                            let q = await opts.collectionSnapshots(await opts.collection(opts.firestore, `${parent._docRef.path}/${p}`)).pipe((0, operators_1.take)(1)).toPromise();
                            if (opts === null || opts === void 0 ? void 0 : opts.debug) {
                                console.log(`${parent._docRef.path}/${p}`, q);
                            }
                            parent[p] = await Promise.all(q.map(async (d) => {
                                var _a, _b;
                                let i = loadObject(d, opts);
                                if ((_a = i === null || i === void 0 ? void 0 : i._docRef) === null || _a === void 0 ? void 0 : _a.path) {
                                    let o = Object.assign({}, opts);
                                    if (!stack.includes((_b = i._docRef) === null || _b === void 0 ? void 0 : _b.path)) {
                                        let copy = Object.assign([], stack);
                                        await i.loadAll(o, copy, p);
                                    }
                                }
                                return i;
                            }));
                        }
                        catch (e) {
                            //console.warn(`CHILD ${p}: ${e.toString()}`,  `${parent._docRef.path}/${p}`);
                        }
                    }
                    catch (e) {
                        if (e.toString().match(/code=permission-denied/)) {
                            let m = e.toString().match(/Property (?<role>\S+) is undefined on object./);
                            //console.warn(`CHILD ${p}: ${m?.groups?.role||'?'}`);
                        }
                        else {
                            //console.warn(`CHILD ${p}: ${e.toString()}`, parent[p]._docRef?.path);
                        }
                    }
                }
                else if (parent[p] instanceof Array && parent[p].length && !(parent[p] instanceof Dimension_1.DimensionArray)) {
                    parent[p] = await Promise.all(parent[p].map(async (item) => {
                        var _a, _b, _c;
                        try {
                            if (((_a = item === null || item === void 0 ? void 0 : item._docRef) === null || _a === void 0 ? void 0 : _a.path) && !isUser((_b = item === null || item === void 0 ? void 0 : item._docRef) === null || _b === void 0 ? void 0 : _b.path)) {
                                item = loadObject(await item._docRef.get(), opts);
                                let o = Object.assign({}, opts);
                                if (!stack.includes((_c = item._docRef) === null || _c === void 0 ? void 0 : _c.path)) {
                                    let copy = Object.assign([], stack);
                                    await item.loadAll(o, copy, p);
                                }
                            }
                        }
                        catch (e) {
                            if (e.toString().match(/code=permission-denied/)) {
                                let m = e.toString().match(/Property (?<role>\S+) is undefined on object./);
                                //console.warn(`PROP (2) ${p}: ${m?.groups?.role||'?'}`);
                            }
                            else {
                                //console.warn(`PROP (2) ${p}: ${e.toString()}`, parent[p]._docRef?.path);
                            }
                        }
                        return item;
                    }));
                }
            }
        }));
    }
}
function toJSON(parent, p) {
    var _a, _b;
    let r;
    if (parent[p] instanceof Dimension_1.DimensionArray) {
        r = parent[p].toJSON ? parent[p].toJSON() : parent[p];
    }
    else if (parent[p] instanceof Array) {
        r = (_a = parent[p]) === null || _a === void 0 ? void 0 : _a.map(i => {
            return (i === null || i === void 0 ? void 0 : i.toJSON) ? i.toJSON() : i;
        });
    }
    else if (parent[p]) {
        r = ((_b = parent[p]) === null || _b === void 0 ? void 0 : _b.toJSON) ? parent[p].toJSON() : parent[p];
    }
    else if (parent[p] !== undefined) {
        r = parent[p];
    }
    return r;
}
exports.toJSON = toJSON;
function toMinJSON(parent, p, ignoreDocRef, publish) {
    var _a, _b, _c, _d, _e;
    let r = null;
    if (parent[p] !== undefined) {
        if (parent[p] instanceof Dimension_1.DimensionArray) {
            r = parent[p].toJSON ? parent[p].toJSON() : parent[p];
        }
        else if (parent[p] instanceof Array) {
            r = ((_a = parent[p]) === null || _a === void 0 ? void 0 : _a.reduce((items, i) => {
                i = ((i === null || i === void 0 ? void 0 : i._type) === 'clients' && (i === null || i === void 0 ? void 0 : i.toMiniJSON)) ? i.toMiniJSON() : (i === null || i === void 0 ? void 0 : i.toMinJSON) ? i.toMinJSON(ignoreDocRef, publish) : (i === null || i === void 0 ? void 0 : i.toJSON) ? i.toJSON() : i;
                if (i) {
                    items.push(i);
                }
                return items;
            }, [])) || [];
        }
        else {
            r = (((_b = parent[p]) === null || _b === void 0 ? void 0 : _b._type) === 'clients' && ((_c = parent[p]) === null || _c === void 0 ? void 0 : _c.toMiniJSON)) ? parent[p].toMiniJSON() : ((_d = parent[p]) === null || _d === void 0 ? void 0 : _d.toMinJSON) ? parent[p].toMinJSON(ignoreDocRef, publish) : ((_e = parent[p]) === null || _e === void 0 ? void 0 : _e.toJSON) ? parent[p].toJSON() : parent[p];
        }
    }
    return r;
}
exports.toMinJSON = toMinJSON;
function toFullJSON(parent, p, publish) {
    var _a, _b, _c;
    let r = toMinJSON(parent, p, true, publish);
    if (parent[p] instanceof Dimension_1.DimensionArray) {
        r = parent[p].toJSON ? parent[p].toJSON() : parent[p];
    }
    else if (parent[p] instanceof Array) {
        r = ((_a = parent[p]) === null || _a === void 0 ? void 0 : _a.reduce((items, i) => {
            i = (i === null || i === void 0 ? void 0 : i.toFullJSON) ? i.toFullJSON(publish) : i;
            if (i) {
                items.push(i);
            }
            return items;
        }, [])) || [];
    }
    else if (parent[p]) {
        r = ((_b = parent[p]) === null || _b === void 0 ? void 0 : _b.toFullJSON) ? parent[p].toFullJSON(publish) : ((_c = parent[p]) === null || _c === void 0 ? void 0 : _c.toJSON) ? parent[p].toJSON() : parent[p];
        // if the property is itself a docRef we may need to convert it.
        if (publish && parent[p] && parent[p]['_firestore'] && parent[p].path) {
            parent[p] = publish.fs.doc(parent[p].path.replace(publish.source, publish.dest));
        }
    }
    return r;
}
exports.toFullJSON = toFullJSON;
function toMiniJSON(parent, p, ignoreDocRef) {
    var _a, _b, _c, _d;
    let r;
    if (parent[p] instanceof Dimension_1.DimensionArray) {
        r = parent[p].toJSON ? parent[p].toJSON() : parent[p];
    }
    else if (parent[p] instanceof Array) {
        r = (_a = parent[p]) === null || _a === void 0 ? void 0 : _a.map(i => {
            i = (i === null || i === void 0 ? void 0 : i.toMiniJSON) ? i.toMiniJSON() : (i === null || i === void 0 ? void 0 : i.toMinJSON) ? i.toMinJSON(ignoreDocRef) : (i === null || i === void 0 ? void 0 : i.toJSON) ? i.toJSON() : i;
            if (i && ignoreDocRef) {
                delete i._docRef;
            }
            return i;
        });
    }
    else if (parent[p]) {
        r = ((_b = parent[p]) === null || _b === void 0 ? void 0 : _b.toMiniJSON) ? parent[p].toMiniJSON() : ((_c = parent[p]) === null || _c === void 0 ? void 0 : _c.toMinJSON) ? parent[p].toMinJSON(ignoreDocRef) : ((_d = parent[p]) === null || _d === void 0 ? void 0 : _d.toJSON) ? parent[p].toJSON() : parent[p];
        if (r && ignoreDocRef) {
            delete r._docRef;
        }
    }
    return r;
}
exports.toMiniJSON = toMiniJSON;
function cleanUrl(url, keepQuery) {
    // We never want urls with question marks in them right?
    if (url) {
        url = decodeURIComponent(url); // Strip out stuff like %20.
        if (url.match(/\?/)) {
            if (keepQuery) {
                url = url.replace(/\?/, '-Q-');
            }
            else {
                url = url.split(/\?/)[0];
            }
        }
        // Strip out http(s)
        url = url.replace(/(http|https)\:\/\//g, '');
        // Remove trailing slash
        if (url.match(/\/$/)) {
            url = url.replace(/\/$/, '');
        }
        return url
            .replace(/(http|https)\:\/\//g, '')
            .replace(/www\./, '')
            .replace(/\#/g, '--')
            .replace(/ /g, '-')
            .replace(/\//g, '-')
            .replace(/-$/, '');
    }
}
exports.cleanUrl = cleanUrl;
class ChildArray extends Array {
}
exports.ChildArray = ChildArray;
function getDistanceBetweenAddresses(lat1, lon1, lat2, lon2) {
    if (lat1 && lon1 && lat2 && lon2) {
        // Converts numeric degrees to radians
        let toRad = (Value) => {
            return Value * Math.PI / 180;
        };
        let R = 6371; // km
        let dLat = toRad(lat2 - lat1);
        let dLon = toRad(lon2 - lon1);
        lat1 = toRad(lat1);
        lat2 = toRad(lat2);
        let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d = R * c * 0.621371; // convert to miles.
        return (0, Common_1.round)(d, 0);
    }
    else {
        return 0;
    }
}
exports.getDistanceBetweenAddresses = getDistanceBetweenAddresses;
var ECoreCronManualTasks;
(function (ECoreCronManualTasks) {
    ECoreCronManualTasks["MAIL_CHECK"] = "CORE.checkEmail";
    ECoreCronManualTasks["MAIL_UNWATCH"] = "CORE.unwatchEmail";
    ECoreCronManualTasks["MAIL_FILTERS"] = "CORE.filterEmail";
    ECoreCronManualTasks["CONTACT_COUNTS"] = "CORE.updateContactCounts";
    ECoreCronManualTasks["CONTACT_SHOW"] = "CORE.showContactEmails";
})(ECoreCronManualTasks || (exports.ECoreCronManualTasks = ECoreCronManualTasks = {}));
var ECoreCronTasks;
(function (ECoreCronTasks) {
    ECoreCronTasks["FOLLOW_UPS"] = "CORE.processFollowUps";
    ECoreCronTasks["CLEAN_OLD_TOKENS"] = "CORE.cleanTokens";
    ECoreCronTasks["CLEAN_OLD_FEEDBACK"] = "CORE.cleanFeedback";
    ECoreCronTasks["CLEAN_OLD_MESSAGES"] = "CORE.flushMessages";
    ECoreCronTasks["CLEAN_OLD_UNREADS"] = "CORE.cleanUnreads";
    ECoreCronTasks["CLEAN_OLD_BIGQUERY_QUEUE"] = "CORE.cleanBigQueryQueue";
    ECoreCronTasks["CLEAN_DEV"] = "CORE.cleanDevOnlyStuff";
    ECoreCronTasks["CLEAN_METRICS"] = "CORE.cleanMetrics";
    ECoreCronTasks["BIGQUERY_PROCESS_QUEUE"] = "CORE.cleanBigQueryQueue";
    ECoreCronTasks["METRICS_PROCESS"] = "CORE.processMetrics";
    ECoreCronTasks["CMS_COPY"] = "CORE.copyFilesToProd";
    ECoreCronTasks["MAIL_WATCH"] = "CORE.watchEmail";
})(ECoreCronTasks || (exports.ECoreCronTasks = ECoreCronTasks = {}));
var ENxtCronTasks;
(function (ENxtCronTasks) {
    ENxtCronTasks["TRACK_SHIPMENTS"] = "NXT.trackShipments";
    ENxtCronTasks["EASYPOST_TRACKER_CLEAN"] = "NXT.cleanOutOldTrackers";
    ENxtCronTasks["STRIPE_POLL_FOR_CHANGES"] = "NXT.pollForPaymentIntentChanges";
})(ENxtCronTasks || (exports.ENxtCronTasks = ENxtCronTasks = {}));
