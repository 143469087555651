import {Component, Input, OnInit} from '@angular/core';
import {ClientService} from "@library/shared/_services/client.service";
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';


@Component({
    selector: 'policy',
    standalone: true,
    template: `
        <div [class]="class" [innerHTML]="sanitizedAgreementText"></div>
    `
})

export class PolicyComponent implements OnInit {
    @Input() documents: string[];
    @Input()type:string=''
    @Input() class: string;
    agreements:any={}
    sanitizedAgreementText: SafeHtml;

    constructor(
        private cSvc:ClientService,
        private sanitizer: DomSanitizer
    ) {
    }

    async ngOnInit() {
        [this.agreements] = await this.cSvc.callAPI('/client/getAgreements', 'post', { type: this.type, documents: this.documents });
        if(this.agreements){
            this.sanitizedAgreementText = this.sanitizer.bypassSecurityTrustHtml(this.agreements.agreement_text);
        }
    }
}
