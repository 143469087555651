import {Component, OnInit} from '@angular/core';
import {PageService} from "@library/shared/_services/page.service";
import {PolicyComponent} from "../_components/policy/policy.component";

@Component({
    selector: 'terms-of-use',
    standalone: true,
    imports: [
        PolicyComponent
    ],
    template: `
        <policy [class]="'mt-28 sm:px-10'" [documents]="document"></policy>
    `
})

export class TermsOfUsePage implements OnInit {
    document:string[]=['Terms of Use']

    constructor(
        private pSvc: PageService
    ) {}

    ngOnInit() {
        this.pSvc.titleService.setTitle(`Equipio - Terms of Use`);
    }
}
