import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {SwUpdate} from '@angular/service-worker';

import {take} from 'rxjs/operators';

import {AlertDialog} from '@library/shared/alert/alert.dialog';
import {ModalDialog} from '@library/shared/modal/modal.dialog';

import {IModalOptions, PageService} from '@library/shared/_services/page.service';
import {AnalyticsService} from '@library/shared/_services/analytics.service';
import {ThemeService} from '@library/shared/_services/theme.service';

import {RootConsumerAppComponent} from '@library/shared/_inherited/root-consumer-app.component';
import { documentTypes } from './config/configs';

@Component({
    selector: 'app-root',
    template: `
        <eqip-tos-header
            [documentTypes]="documentTypes">
        </eqip-tos-header>
        <notification-dialog></notification-dialog>
        <router-outlet></router-outlet>
        <!--        <eqip-custom-loader-component [block]="pSvc.loading$|async"></eqip-custom-loader-component>-->
        <ng-container #modalContainer></ng-container>
    `
})
export class AppComponent extends RootConsumerAppComponent {
    @ViewChild('modalContainer', {static: true, read: ViewContainerRef}) modalContainer: ViewContainerRef;
    documentTypes = documentTypes;
    constructor(
        public pSvc: PageService,
        private aSvc: AnalyticsService,
        private sanitizer: DomSanitizer,
        private swUpdate: SwUpdate,
        tSvc: ThemeService,
        router: Router,
    ) {

        super(pSvc, tSvc, router);

        this.pSvc.modal$.subscribe(
            (opts: IModalOptions) => {
                setTimeout(() => {
                    if (opts) {
                        let ref: any = this.modalContainer.createComponent<any>(ModalDialog);
                        if (ref.instance?.init) {
                            ref.instance.init(opts);
                        }
                        if (!ref.instance.onClose) {
                            console.warn('Component is missing an onClose event.');
                        }
                        ref.instance.onClose?.pipe(take(1))
                            .subscribe(e => {
                                // Fake an esc click
                                this.pSvc.clickEsc$.next(true);
                            });
                    }
                });
            }
        );

        this.pSvc.clickEsc$
            .subscribe(
                r => {
                    if (r) {
                        if (this.modalContainer.length) {
                            this.modalContainer.remove(this.modalContainer.length - 1);
                        }
                    }
                }
            );

        this.pSvc.alert$
            .subscribe(
                (e: any) => {
                    if (e) {
                        let alert: any = {};
                        if (e.title || e.buttons || e.options || e.error?.title) {
                            alert = e.error?.title ? e.error : e;
                        } else if (e.error) {
                            let alert: any = {};
                            ['title', 'message', 'body', 'details'].forEach(p => {
                                alert[p] = (e.error[p] instanceof String && e.error[p].toString() !== 'undefined') ? e.error[p].toString() : '';
                            });
                            ['body', 'details'].forEach(prop => {
                                if (alert[prop]) {
                                    alert.message += ` ${alert[prop]}`;
                                }
                            });
                            ['name'].forEach(prop => {
                                if (alert[prop]) {
                                    alert.title += ` ${alert[prop]}`;
                                }
                            });
                            // console.warn('AFTER', alert);
                            alert = alert;
                            alert.title = alert.title || 'An Error Occurred';
                            alert.message = alert.message || 'Please try again. If the problem persists please contact support.';
                        }
                        if (!alert.title && e.statusText) {
                            alert.title = e.statusText;
                            alert.message = e.message || '';
                        }
                        const viewportWidth = window.innerWidth;
                        const modalWidth = viewportWidth < 768 ? '90vw' : '25vw';
                        const modalHeight = viewportWidth < 768 ? '15vh !important' : '20vh !important'
                        if(!alert.styles){
                            alert.styles =  {
                                position: 'fixed',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    'z-index': '1000',
                                    width: modalWidth,
                                    'min-height':`20vh !important`,
                                    'max-height':modalHeight,
                                    display: 'flex',
                                    flexDirection: 'column'
                            }
                        }
                        this.pSvc.modal$.next({
                            component: AlertDialog,
                            buttons: alert.buttons,
                            label: alert.title,
                            onLoaded: (comp: AlertDialog) => {
                                comp.alert = alert;
                                if (e.html) {
                                    comp.html = this.sanitizer.bypassSecurityTrustHtml(e.html);
                                }
                                this.pSvc.loading$.next(false);;
                                this.pSvc.blocking$.next(false);
                            },
                            styles: alert.styles
                        });
                    }
                }
            );

        if (this.swUpdate.isEnabled) {
            this.swUpdate.versionUpdates.subscribe((u) => {
                console.log(u.type);
                if (u?.type === 'VERSION_DETECTED' || u?.type === 'VERSION_INSTALLATION_FAILED') {
                    window.location.reload();
                }
            });
        }

    }
}
