"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubQuote = exports.EqipQuote = exports.EqipCart = exports.EqipOrder = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
const exclude = ['amount_paid', 'amount_refunded', 'num_delivered', 'num_shipped', 'replacement', 'selected_rate', 'shipping', 'total_weight'];
class EqipOrder extends model_core_1.Order {
    constructor(data, olMap, type, idLen) {
        super(data, olMap || index_1.olm, type || 'orders', idLen || 26);
        this.subquotes = new model_core_1.ChildArray();
        this.data = {};
        this.production_details = {};
        this.metadata = {};
        this.itemsSaved = false;
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, EqipOrder);
        this._s = [...this._s, 'items', 'stages', 'subQuotes'];
    }
    async loadAll(opts, stack, p) {
        opts = opts || {};
        opts.loadAllFn = opts.loadAllFn || index_1.loadAll;
        opts.olm = opts.olm || index_1.olm;
        await super.loadAll(opts, stack, p);
    }
    add(p, i) {
        if (p === 'items') {
            if (!this.items.find(i => i.sku === i.sku)) {
                i.quantity = i.quantity || 1;
                this.items.push(i);
                if (this._docRef) {
                    i.save(this);
                    this.save();
                }
            }
        }
        else {
            super.add(p, i);
        }
    }
    async save(parent) {
        if (!this._docRef) {
            await super.save(parent);
        }
        if (this.subquotes && this.subquotes.length > 0) {
            const subQuotesCollection = this._docRef.collection('subquotes');
            for (const subQuote of this.subquotes) {
                if (!subQuote._docRef) {
                    subQuote._docRef = subQuotesCollection.doc(subQuote.id);
                }
                await subQuote.save(this);
                if (subQuote.items && subQuote.items.length > 0) {
                    const itemPromises = subQuote.items.map(async (item) => {
                        return item.save(subQuote);
                    });
                    await Promise.all(itemPromises);
                }
            }
        }
        if (this.items && this.items.length > 0) {
            const itemsCollection = this._docRef.collection('items');
            const itemPromises = this.items.map(async (item) => {
                if (!item._docRef) {
                    item._docRef = itemsCollection.doc();
                }
                return item.save(this);
            });
            await Promise.all(itemPromises);
        }
        await super.save(parent);
    }
    async delete() {
        if (!this._docRef) {
            throw `Cannot delete ${this._type}! No document reference.`;
        }
        try {
            if (this.subquotes && this.subquotes.length > 0) {
                for (const subquote of this.subquotes) {
                    await subquote.delete();
                }
            }
            await super.delete();
        }
        catch (error) {
            console.error(`Error deleting ${this._type}:`, error);
            throw new Error(`Failed to delete ${this._type}.`);
        }
    }
    async clearItems() {
        if (!this._exists)
            throw new Error("Quote doesn't exist.");
        try {
            const itemsCollectionRef = this._docRef.collection('items');
            const snapshot = await itemsCollectionRef.get();
            const deletePromises = snapshot.docs.map(doc => itemsCollectionRef.doc(doc.id).delete());
            await Promise.all(deletePromises);
        }
        catch (error) {
            console.error("Error clearing items:", error);
            throw new Error("Failed to clear items.");
        }
    }
    async addItems(items) {
        if (!this._exists)
            throw new Error("Quote doesn't exist.");
        try {
            const itemsCollectionRef = this._docRef.collection('items');
            const addPromises = items.map(item => {
                const newItemRef = itemsCollectionRef.doc();
                return newItemRef.set(item);
            });
            await Promise.all(addPromises);
        }
        catch (error) {
            console.error("Error adding items:", error);
            throw new Error("Failed to add items.");
        }
    }
    async updateItems() {
        try {
            if (!this._exists) {
                throw new Error("Order doesn't exist. Cannot update items.");
            }
            const itemsCollection = this._docRef.collection('items');
            const savePromises = this.items.map(item => {
                if (!item._docRef) {
                    item._docRef = itemsCollection.doc();
                }
                return item.save(this);
            });
            await Promise.all(savePromises);
            await this.calc();
            await this.save();
            console.log("Items updated and saved successfully.");
        }
        catch (error) {
            console.error("Failed to update items:", error);
            throw new Error("Failed to update items.");
        }
    }
    //Was not needed but will leave for now
    updateFrom(data) {
        for (const [key, value] of Object.entries(data)) {
            if (this.hasOwnProperty(key)) {
                const descriptor = Object.getOwnPropertyDescriptor(this, key);
                if ((descriptor === null || descriptor === void 0 ? void 0 : descriptor.writable) || (descriptor === null || descriptor === void 0 ? void 0 : descriptor.set)) {
                    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                        if (typeof this[key] === 'object' && this[key] !== null) {
                            Object.assign(this[key], value);
                        }
                        else {
                            this[key] = Object.assign({}, value);
                        }
                    }
                    else {
                        this[key] = value;
                    }
                }
            }
        }
    }
}
exports.EqipOrder = EqipOrder;
class EqipCart extends EqipOrder {
    constructor(data, olMap) {
        super(data, olMap);
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, EqipCart);
        this.excludeProperties();
    }
    excludeProperties() {
        exclude.forEach((prop) => {
            delete this[prop];
        });
    }
    async copyToOrder(parent, keep) {
        await this.loadAll();
        return new EqipOrder(await super.clean(new EqipOrder(this), parent, keep || ['items'], null, true));
    }
}
exports.EqipCart = EqipCart;
class EqipQuote extends EqipOrder {
    constructor(data, olMap) {
        super(data, olMap, 'quotes', 26);
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, EqipQuote);
        this._s = [...this._s, 'items', 'stages', 'subQuotes'];
    }
    async copy(parent) {
        return new EqipQuote(await super.clean(new EqipQuote(this), parent, ['items', 'subQuotes'], null, true));
    }
    async copyToOrder(parent) {
        await this.loadAll();
        return new EqipOrder(await super.clean(new EqipOrder(this), parent, ['items', 'subQuotes'], null, true));
    }
    toString() {
        return `Quote: ${this.quote_id}`;
    }
    async updateQuote(changes) {
        if (!this._exists)
            throw new Error("Quote doesn't exist.");
        await this._docRef.update(changes);
    }
    async initializeQuote(quoteId) {
        this._docRef = this.fs.collection('quotes').doc(quoteId);
        const doc = await this._docRef.get();
        if (!doc.exists) {
            throw new Error(`No quote found with id: ${quoteId}`);
        }
        this._exists = true;
    }
}
exports.EqipQuote = EqipQuote;
class SubQuote extends EqipOrder {
    constructor(data, olMap) {
        super(data, olMap, 'subquotes', 26);
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, SubQuote);
        this._s = [...this._s, 'items', 'stages', 'subquotes'];
    }
    async copy(parent) {
        return new SubQuote(await super.clean(new SubQuote(this), parent, ['items', 'subquotes'], null, true));
    }
    async copyToOrder(parent) {
        await this.loadAll();
        return new EqipOrder(await super.clean(new EqipOrder(this), parent, ['items', 'subquotes'], null, true));
    }
    toString() {
        return `Quote: ${this.quote_id}`;
    }
}
exports.SubQuote = SubQuote;
index_1.olm.orders = (ref, map) => {
    return new EqipOrder(ref, map);
};
index_1.olm.quotes = (ref, map) => {
    return new EqipQuote(ref, map);
};
index_1.olm.subquotes = (ref, map) => {
    return new SubQuote(ref, map);
};
