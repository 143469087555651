import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-pagination',
    standalone:true,
    imports: [CommonModule],
    template: `<div class="flex flex-col items-center space-y-2">
        <p class="text-md md:text-lg lg:text-xl">{{ currentPage + 1 }} of {{ totalPages }}</p>
        <div class="flex justify-center items-center space-x-1 sm:space-x-2 md:space-x-3 lg:space-x-4 p-2 sm:p-3 md:p-4 lg:p-5 rounded">
            <button *ngIf="currentPage > 0"
                    class="bg-accent-800 hover:bg-gray-900 text-white font-bold py-1 px-2 md:text-md lg:text-lg rounded"
                    (click)="changePage(0)">First</button>
            <button *ngIf="currentPage > 0"
                    class="bg-accent-800 hover:bg-gray-900 text-white font-bold py-1 px-2 md:text-md lg:text-lg rounded"
                    (click)="changePage(currentPage - 1)">Prev</button>
            <button *ngFor="let page of pageNumbers"
                    class="bg-accent-800 hover:bg-gray-900 text-white font-bold py-1 px-2 md:text-md lg:text-lg rounded"
                    [ngClass]="{
                'bg-accent-800 hover:bg-gray-900 text-white': page !== currentPage,
                'bg-white text-black border border-black': page === currentPage
            }"
                    (click)="changePage(page)">{{ page + 1 }}</button>
            <button *ngIf="currentPage < totalPages - 1"
                    class="bg-accent-800 hover:bg-gray-900 text-white font-bold py-1 px-2 md:text-md lg:text-lg rounded"
                    (click)="changePage(currentPage + 1)">Next</button>
            <button *ngIf="currentPage < totalPages - 1"
                    class="bg-accent-800 hover:bg-gray-900 text-white font-bold py-1 px-2 md:text-md lg:text-lg rounded"
                    (click)="changePage(totalPages - 1)">Last</button>
        </div>
    </div>
    `
})
export class PaginationComponent {
    @Input() currentPage = 0;
    @Input() totalPages = 0;
    @Output() pageChanged = new EventEmitter<number>();
    pageNumbers: number[] = [];

    ngOnChanges(changes: SimpleChanges): void {
        this.updatePageNumbers();
    }

    updatePageNumbers() {
        const maxPageNumbersToShow = 7;
        const middlePageIndex = Math.floor(maxPageNumbersToShow / 2);
        let startPageIndex = Math.max(this.currentPage - middlePageIndex, 0);
        let endPageIndex = startPageIndex + maxPageNumbersToShow - 1;

        if (endPageIndex >= this.totalPages) {
            endPageIndex = this.totalPages - 1;
            startPageIndex = Math.max(endPageIndex - maxPageNumbersToShow + 1, 0);
        }

        this.pageNumbers = [];
        for (let i = startPageIndex; i <= endPageIndex; i++) {
            this.pageNumbers.push(i);
        }
    }

    changePage(page: number) {
        const currentScrollPosition = window.scrollY;
        const scrollAmount = currentScrollPosition * 0.7;
        const newScrollPosition = Math.max(currentScrollPosition - scrollAmount, 0);

        window.scrollTo({
            top: newScrollPosition,
            behavior: 'smooth'
        });
        this.pageChanged.emit(page);
    }
}
