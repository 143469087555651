import {
    Component,
    Input,
    ElementRef,
    HostListener,
    ChangeDetectorRef,
    OnInit,
    SimpleChanges,
    OnChanges
} from '@angular/core';
import {ICmsConfigFile, Page} from '@nxt/model-core';
import { CommonModule } from '@angular/common';
import {NavigationEnd, Router, RouterLink} from '@angular/router';
import {EqipCartService} from "../../services/eqip.cart.service";
import {IconsComponent} from "../../../../../_library/shared/icons/icons.component";
import {SharedService} from "../../services/shared.service";
import {ConsumerAccountService} from "../../../../../_library/consumer/_services/consumer.account.service";
import {PageService} from "../../../../../_library/shared/_services/page.service";
import {ClientService} from "../../../../../_library/shared/_services/client.service";
import {OnDestroyPage} from "../../../../../_library/shared/_inherited/ondestroy.page";
import {takeUntil} from "rxjs/operators";
import {filter, firstValueFrom} from "rxjs";
import {EqipContact} from "@nxt/model-eqip";
import {EqipNavDropdownComponent} from "./eqip-nav-dropdown.component";
import {RemoveDuplicatesDirective} from "../../directives/remove-duplicates.directive";
export const config: ICmsConfigFile = {
    selector: 'new-eqip-nav-header',
    description: 'Equipio navigation bar with dropdowns',
    config: [
        {
            label: 'Logo URL',
            property: 'logo',
            type: 'image',
            component: 'input-image-new',
            inputImageResizeOpts: {
                maxByteSize: 200000,
                multiple: false,
                allowManualUrl: true,
                instructions: 'Select logo image'
            },
            required: true,
            config: null
        },
        {
            label: 'Menu Items',
            property: 'menuItems',
            type: 'array',
            required: true,
            config: [
                {
                    label: 'Label',
                    property: 'label',
                    type: 'text',
                    component: 'input-stacked-text',
                    required: true,
                    config: null
                },
                {
                    label: 'Key(lower case label for dropdown only)',
                    property: 'key',
                    type: 'text',
                    component: 'input-stacked-text',
                    required: true,
                    config: null
                },
                {
                    label: 'URL',
                    property: 'url',
                    type: 'url',
                    component: 'input-url',
                    required: false,
                    config: null
                },
                {
                    label: 'Has Dropdown?',
                    property: 'hasDropdown',
                    type: 'boolean',
                    component: 'input-checkbox',
                    required: true,
                    config: null
                },
                {
                    label: 'Dropdown Items',
                    property: 'items',
                    type: 'array',
                    required: false,
                    hide: (parent: any, form: any) => {
                        return form.get('hasDropdown').value !== true
                    },
                    config: [
                        {
                            label: 'Dropdown Label',
                            property: 'label',
                            type: 'text',
                            component: 'input-stacked-text',
                            required: true,
                            config: null
                        },
                        {
                            label: 'Disable?',
                            property: 'disable',
                            component: 'input-checkbox',
                            type: 'boolean',
                            config: null
                        },
                        {
                            label: 'Dropdown URL',
                            property: 'url',
                            type: 'url',
                            component: 'input-url',
                            required: true,
                            config: null
                        },
                        {
                            label: 'Dropdown Icon Image',
                            property: 'image',
                            type: 'image',
                            component: 'input-image-new',
                            inputImageResizeOpts: {
                                maxByteSize: 200000,
                                multiple: false,
                                allowManualUrl: true,
                                instructions: 'Select dropdown image'
                            },
                            required: true,
                            config: null
                        },
                        {
                            label: 'Dropdown Description',
                            property: 'description',
                            type: 'text',
                            component: 'input-stacked-text',
                            required: true,
                            config: null
                        },
                        {
                            label: 'Aside Image',
                            property: 'asideImage',
                            type: 'image',
                            component: 'input-image-new',
                            inputImageResizeOpts: {
                                maxByteSize: 200000,
                                multiple: false,
                                allowManualUrl: true,
                                instructions: 'Select dropdown image'
                            },
                            required: true,
                            config: null
                        },
                        {
                            label: 'Dropdown Aside Title',
                            property: 'asideTitle',
                            type: 'text',
                            component: 'input-stacked-text',
                            required: true,
                            config: null
                        },
                        {
                            label: 'Dropdown Aside Text Description',
                            property: 'asideText',
                            type: 'text',
                            component: 'input-stacked-text',
                            required: true,
                            config: null
                        },
                    ]
                }
            ]
        },
        {
            label: 'Dropdown Footer Links Row ONE',
            property: 'footer_row',
            type: 'array',
            required: false,
            config: [
                {
                    label: 'Label',
                    property: 'label',
                    type: 'text',
                    component: 'input-stacked-text',
                    required: false,
                    config: null
                },
                {
                    label: 'URL',
                    property: 'url',
                    type: 'url',
                    component: 'input-url',
                    required: false,
                    config: null
                }
            ]
        },
        {
            label: 'Dropdown Footer Links Row TWO',
            property: 'footer_row_two',
            type: 'array',
            required: false,
            config: [
                {
                    label: 'Label',
                    property: 'label',
                    type: 'text',
                    component: 'input-stacked-text',
                    required: false,
                    config: null
                },
                {
                    label: 'URL',
                    property: 'url',
                    type: 'url',
                    component: 'input-url',
                    required: false,
                    config: null
                }
            ]
        }
    ]
}

@Component({
    selector: 'new-eqip-nav-header',
    standalone: true,
    imports: [CommonModule, RouterLink, IconsComponent, EqipNavDropdownComponent, RemoveDuplicatesDirective],
    template: `
        <header removeDuplicates
            (mouseleave)="handleMouseLeave()"
            [ngClass]="{'bg-transparent': isTransparent, 'bg-accent-800 shadow-md': !isTransparent}"
            class="fixed top-0 left-0 w-full z-50 transition-all duration-300"
        >
            <nav class="flex justify-between items-center w-full min-h-20 px-4 lg:px-32">
                <div class="flex items-center gap-4">
                    <div class="w-[140px]">
                        <a routerLink="/" class="cursor-pointer">
                            <img [src]="imageUrl"
                                 alt="Company logo"
                                 class="object-contain w-[112px]" />
                        </a>
                    </div>

                    <div class="hidden lg:flex items-center gap-5 text-base font-semibold text-white">
                        <ng-container *ngFor="let item of section?.menuItems">
                            <div *ngIf="item.hasDropdown" class="relative">
                                <button type="button" (click)="toggleDropdown(item.key, $event)"
                                        class="flex items-center gap-1 py-2 focus:outline-none">
                                    <span>{{ getActiveLabelForDropdown(item) }}</span>
                                    <icon name="heroicon-outline-chevron-down" class="w-5 h-5 transition-transform duration-300"
                                          [ngClass]="{'rotate-180': dropdowns[item.key], 'rotate-0': !dropdowns[item.key]}"></icon>
                                </button>
                                <eqip-nav-dropdown *ngIf="dropdowns[item.key]"
                                                   [items]="item.items"
                                                   (mouseLeft)="dropdowns[item.key] = false"
                                                   (itemSelected)="updateDropdownLabel(item.key, $event)"
                                >

                                </eqip-nav-dropdown>
                            </div>
                            <a *ngIf="!item.hasDropdown" [routerLink]="item.url" class="py-2">{{ item.label }}</a>
                        </ng-container>
                    </div>
                </div>

                <div class="flex items-center gap-4">
                    <a *ngIf="(cSvc.u$ | async)?.isAnonymous" routerLink="/u/account"
                       class="hidden lg:block px-4 py-2 bg-accent-700 hover:bg-accent-600 rounded-lg shadow-sm border-white text-base font-semibold text-white">
                        Sign In
                    </a>
                    <div class="relative hidden lg:block" *ngIf="!(cSvc.u$ | async)?.isAnonymous">
                        <a (click)="toggleDropdown('account', $event)" class="cursor-pointer flex items-center gap-2">
                            <icon strokeWidth="1.6" name="heroicon-outline-user-circle" class="w-10 h-10 text-white"></icon>
                        </a>

                        <div *ngIf="dropdowns.account"
                             (mouseleave)="dropdowns.account = false"
                             class="absolute right-0 top-full mt-2 bg-white rounded-lg border border-gray-300 shadow-md z-50 w-48 text-sm">

                            <a *ngIf="contact?.verified" routerLink="/u/account"
                               class="flex gap-2 items-center px-4 py-2 hover:bg-gray-100 rounded-t-md">
                                <icon name="heroicon-outline-clipboard-list" class="w-5 h-5 text-gray-600"></icon>
                                RFQ History
                            </a>
                            <a *ngIf="contact?.verified && contact?.type==='vendor'" routerLink="/u/account/vendor"
                               class="flex gap-2 items-center px-4 py-2 hover:bg-gray-100">
                                <icon name="heroicon-outline-briefcase" class="w-5 h-5 text-gray-600"></icon>
                                Vendor RFQ History
                            </a>
                            <a routerLink="/u/account/profile"
                               class="flex gap-2 items-center px-4 py-2 hover:bg-gray-100">
                                <icon name="heroicon-outline-user" class="w-5 h-5 text-gray-600"></icon>
                                Profile
                            </a>
                            <a routerLink="/u/account/support"
                               class="flex gap-2 items-center px-4 py-2 hover:bg-gray-100">
                                <icon name="heroicon-outline-support" class="w-5 h-5 text-gray-600"></icon>
                                Support
                            </a>

                            <div class="border-t border-gray-300"></div>

                            <a (click)="signOut()"
                               class="flex gap-2 items-center px-4 py-2 hover:bg-red-50 text-red-600 cursor-pointer rounded-b-md">
                                <icon name="heroicon-outline-logout" class="w-5 h-5"></icon>
                                Sign Out
                            </a>
                        </div>
                    </div>

                    <div routerLink="/u/checkout" class="relative order-1 lg:order-none cursor-pointer">
                        <icon strokeWidth="1.6" name="heroicon-outline-shopping-cart" class="w-10 h-10 text-white"></icon>
                        <span *ngIf="(ecSvc.cartDocument$|async)?.checkout?.items?.length" class="notification-badge">
          {{ (ecSvc.cartDocument$|async)?.checkout?.items?.length }}
        </span>
                    </div>

                    <button (click)="toggleMenu()" class="lg:hidden text-white order-2">
                        <icon *ngIf="!isMenuOpen" strokeWidth="1.6" name="heroicon-outline-menu" class="w-10 h-10"></icon>
                        <icon *ngIf="isMenuOpen" strokeWidth="1.6" name="heroicon-outline-x" class="w-10 h-10"></icon>
                    </button>
                </div>
            </nav>

            <div *ngIf="isMenuOpen"
                 class="px-3 pb-6 bg-white max-w-[400px] shadow-lg z-50 absolute top-20 left-0 w-full">
                <nav class="text-base font-semibold text-gray-900">
                    <div class="py-5 w-full border-b border-gray-200">

                        <ng-container *ngFor="let item of section?.menuItems">
                            <button *ngIf="item.hasDropdown" (click)="toggleDropdown(item.key, $event)"
                                    class="flex gap-3 items-center px-4 py-3 w-full hover:bg-gray-100">
                                <span class="flex-1 text-left">{{ getActiveLabelForDropdown(item) }}</span>
                                <icon name="heroicon-outline-chevron-right"
                                      class="w-5 h-5 transition-transform duration-200"
                                      [ngClass]="{'rotate-90': dropdowns[item.key]}"></icon>
                            </button>

                            <eqip-nav-dropdown *ngIf="dropdowns[item.key]"
                                               [items]="item.items"
                                               (mouseLeft)="dropdowns[item.key] = false"
                                               (itemSelected)="updateDropdownLabel(item.key, $event)"
                            >


                            </eqip-nav-dropdown>

                            <a *ngIf="!item.hasDropdown" [routerLink]="item.url" (click)="isMenuOpen = false"
                                class="block px-4 py-3 hover:bg-gray-100">
                                {{ item.label }}
                            </a>
                        </ng-container>

                        <button *ngIf="!(cSvc.u$ | async)?.isAnonymous" (click)="toggleDropdown('account', $event)"
                                class="flex gap-3 items-center px-4 py-3 w-full hover:bg-gray-100">
                            <span class="flex-1 text-left">Account</span>
                            <icon name="heroicon-outline-chevron-right"
                                  class="w-5 h-5 transition-transform duration-200"
                                  [ngClass]="{'rotate-90': dropdowns.account}"></icon>
                        </button>
                        <button *ngIf="(cSvc.u$ | async)?.isAnonymous" routerLink="/u/account"
                                class="flex gap-3 items-center px-4 py-3 w-full hover:bg-gray-100">
                            <span class="flex-1 text-left">Sign in</span>
                        </button>
                        <div *ngIf="dropdowns.account && contact" class="bg-gray-50 text-sm">
                            <a *ngIf="contact?.verified" routerLink="/u/account" (click)="isMenuOpen = false" class="flex gap-2 items-center block px-10 py-2 hover:bg-gray-100">
                            <icon name="heroicon-outline-clipboard-list" class="w-4 h-4 text-gray-600"></icon>
                            RFQ History
                            </a>
                            <a *ngIf="contact?.verified && contact?.type==='vendor'" (click)="isMenuOpen = false" routerLink="/u/account/vendor"
                               class="flex gap-2 items-center block px-10 py-2 hover:bg-gray-100">
                              <icon name="heroicon-outline-briefcase" class="w-4 h-4 text-gray-600"></icon>
                               Vendor RFQ History
                               </a>
                            <a routerLink="/u/account/profile" (click)="isMenuOpen = false" class="flex gap-2 items-center block px-10 py-2 hover:bg-gray-100">
                           <icon name="heroicon-outline-user" class="w-4 h-4 text-gray-600"></icon>
                            Profile

                            </a>
                            <a routerLink="/u/account/support" (click)="isMenuOpen = false" class="flex gap-2 items-center block px-10 py-2 hover:bg-gray-100">
                            <icon name="heroicon-outline-support" class="w-4 h-4 text-gray-600"></icon>
                            Support</a>
                            <a (click)="signOut()" class="flex gap-2 items-center block px-10 py-2 cursor-pointer hover:bg-gray-100">
                            <icon name="heroicon-outline-logout" class="text-red-500 w-4 h-4"></icon>
                            Sign Out</a>
                        </div>
                    </div>

                    <footer class="px-4 py-6">
                        <div class="flex gap-6 text-sm text-gray-600">
                            <div class="flex flex-col gap-3">
                                <a *ngFor="let link of section?.footer_row"
                                   (click)="isMenuOpen = false"
                                   [routerLink]="link.url"
                                   class="hover:text-accent-700 transition-colors">{{ link.label }}</a>
                            </div>
                            <div class="flex flex-col gap-3">
                                <a *ngFor="let link of section?.footer_row_two"
                                   (click)="isMenuOpen = false"
                                   [routerLink]="link.url"
                                   class="hover:text-accent-700 transition-colors">{{ link.label }}</a>
                            </div>
                        </div>
                    </footer>
                </nav>
            </div>
        </header>

    `,
    styles: [
        `
            //had to do this because for some reason my tailwind positioning worked for Chrome and Safari, But on firefox it did not.
            //I would get it to work on firefox it would break chrome. So I finally got this solution to work for both.
            .notification-badge {
                position: absolute;
                top: 2px;
                right: 3px;
                transform: translate(50%, -50%);
                background-color: rgb(239, 68, 68);
                color: white;
                border-radius: 50%;
                width: 1.5rem;
                height: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.75rem;
                line-height: 1.5rem;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
                pointer-events: none;
            }
        `
    ]
})
export class EqipNavHeaderComponent extends OnDestroyPage implements OnInit,OnChanges {
    @Input() page: Page;
    @Input() section: any;
    dropdowns = {
        equipment: false,
        account: false
    };
    defaultLabels: { [key: string]: string } = {};
    selectedDropdown: { [key: string]: string } = {};

    isMenuOpen = false;
    isTransparent = false;
    contact: EqipContact;
    isOnHomePage = false;
    private homePageUrls: string[] = ['/', '/playground','/how-it-works','/for-producers', '/for-vendors'];

    constructor(private elementRef: ElementRef,
                public ecSvc: EqipCartService,
                public acctSvc: ConsumerAccountService,
                public pSvc: PageService,
                public cSvc: ClientService,
                private router: Router,
                private sSvc: SharedService,
                private cdr: ChangeDetectorRef,
    ) {
        super()
        // this.pSvc.page$
        //     .pipe(takeUntil(this.d$))
        //     .subscribe(page => {
        //         this.page = page;
        //         console.log('Page Loaded:', this.page);
        //         this.cdr.detectChanges();
        //     });
    }

    get imageUrl() {
        return this.section?.logo ?? '/assets/images/eqip-blue-logo.png';
    }

    async ngOnInit(): Promise<void> {
        this.checkCurrentRoute();

        this.cSvc.u$
            .pipe(takeUntil(this.d$))
            .subscribe(async (user) => {
                if (user && !user?.isAnonymous) {
                    this.sSvc.contact$
                        .pipe(takeUntil(this.d$))
                        .subscribe(contact => {
                            if (contact) {
                                this.contact = contact;
                                this.cdr.detectChanges();
                            } else {
                                setTimeout(async () => {
                                    const fetchedContact = await firstValueFrom(this.sSvc.contact$);
                                    if (fetchedContact) {
                                        this.contact = fetchedContact;
                                        this.cdr.detectChanges();
                                    }
                                }, 3100);
                            }
                        });
                }
            });

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                takeUntil(this.d$)
            )
            .subscribe(() => {
                this.checkCurrentRoute();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.section || this.page?.header?.content?.sections?.length) {
            this.section = this.section || this.page?.header.content.sections[0];
        }

    }
    toggleDropdown(dropdownKey: string, event: Event) {
        event.stopPropagation();
        this.dropdowns[dropdownKey] = !this.dropdowns[dropdownKey];

        Object.keys(this.dropdowns).forEach(key => {
            if (key !== dropdownKey) {
                this.dropdowns[key] = false;
            }
        });
    }

    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
    }
    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.dropdowns.equipment = false;
            this.dropdowns.account = false;
        }
    }

    @HostListener('window:resize')
    onResize() {
        this.isMenuOpen = false;
        this.dropdowns.equipment = false;
        this.dropdowns.account = false;
    }

    @HostListener('document:touchstart', ['$event'])
    onTouchStart(event: TouchEvent) {
        const touchedElement = event.target as HTMLElement;

        if (!this.elementRef.nativeElement.contains(touchedElement)) {
            this.dropdowns.equipment = false;
            this.dropdowns.account = false;

            if (this.isMenuOpen) {
                this.isMenuOpen = false;
                this.cdr.detectChanges();
            }
        }
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const threshold = window.innerHeight * 0.1;

        if (this.isOnHomePage) {
            this.isTransparent = scrollTop <= threshold;
        }

        this.cdr.detectChanges();
    }

    handleMouseLeave() {
        this.dropdowns.equipment = false;
        this.dropdowns.account = false;
    }

    private checkCurrentRoute() {
        this.isOnHomePage = this.homePageUrls.includes(this.router.url);
        this.isTransparent = this.isOnHomePage;
        this.cdr.detectChanges();
    }

    signOut() {
        const viewportWidth = window.innerWidth;
        const modalWidth = viewportWidth < 768 ? '80vw' : '25vw';
        const modalHeight = viewportWidth < 768 ? '15vh !important' : '20vh !important'

        this.pSvc.alert$.next({
            title: 'Log Out?',
            message: 'Are you sure you want to log out?',
            buttons: [
                {label: 'Cancel', closeOnClick: true},
                {
                    label: 'Log Out',
                    class: 'btn-dark',
                    closeOnClick: true,
                    click: async () => {
                        await this.acctSvc.signOut();
                        if (this.router.url.startsWith('/u/account')) {
                            this.router.navigateByUrl('/account');
                        } else {
                            location.reload();
                        }
                    }
                }
            ],
            styles: {
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                'z-index': '1000',
                width: modalWidth,
                'min-height': `20vh !important`,
                'max-height':modalHeight,
                display: 'flex',
                flexDirection: 'column'
            },
        });
    }

    updateDropdownLabel(dropdownKey: string, selectedLabel: string) {
        this.selectedDropdown[dropdownKey] = selectedLabel;
        this.dropdowns[dropdownKey] = false;
        this.isMenuOpen=false;
    }

    getActiveLabelForDropdown(item: any): string {
        const currentUrl = this.router.url;
        const matchingItem = item.items?.find((dropItem: any) => currentUrl.startsWith(dropItem.url));
        if (matchingItem) {
            return this.selectedDropdown[item.key] || matchingItem.label;
        }
        this.selectedDropdown[item.key] = '';
        return item.label;
    }

}
