
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterOffScreenDirective } from "../../directives/hide-footer.directive";
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ClientService} from "@library/shared/_services/client.service";
import {AnalyticsService} from "@library/shared/_services/analytics.service";
import { ICmsConfigFile,Page } from "@nxt/model-core";
import {RemoveDuplicatesDirective} from "../../directives/remove-duplicates.directive";
import {PageService} from "../../../../../_library/shared/_services/page.service";

export const config: ICmsConfigFile = {
    selector: 'eqip-new-footer',
    description: 'Footer with logo, navigation links, and contact form',
    config: [
        {
            label: 'Footer Logo',
            property: 'logo',
            type: 'image',
            component: 'input-image-new',
            inputImageResizeOpts: {
                maxByteSize: 200000,
                multiple: false,
                allowManualUrl: true,
                instructions: 'Upload the Equipio footer logo'
            },
            required: true,
            config: null
        },
        {
            label: 'Navigation Links',
            property: 'navLinks',
            type: 'array',
            required: true,
            config: [
                {
                    label: 'Link Text',
                    property: 'text',
                    type: 'text',
                    component: 'input-stacked-text',
                    required: true,
                    config: null
                },
                {
                    label: 'Link URL',
                    property: 'url',
                    type: 'url',
                    component: 'input-url',
                    required: true,
                    config: null
                }
            ]
        },
        {
            label: 'Contact Message',
            property: 'contactMessage',
            type: 'text',
            component: 'input-stacked-text',
            required: true,
            config: null
        },
        {
            label: 'Contact Button Text',
            property: 'contactButton',
            type: 'text',
            component: 'input-stacked-text',
            required: true,
            config: null
        },
        {
            label: 'Footer Links',
            property: 'footerLinks',
            type: 'array',
            required: true,
            config: [
                {
                    label: 'Footer Link Text',
                    property: 'text',
                    type: 'text',
                    component: 'input-stacked-text',
                    required: true,
                    config: null
                },
                {
                    label: 'Footer Link URL',
                    property: 'url',
                    type: 'url',
                    component: 'input-url',
                    required: true,
                    config: null
                }
            ]
        },
        {
            label: 'Copyright Text',
            property: 'copyright',
            type: 'text',
            component: 'input-stacked-text',
            required: true,
            config: null
        }
    ]
};

@Component({
    selector: 'eqip-new-footer',
    standalone: true,
    imports: [CommonModule, RouterModule, ReactiveFormsModule, FooterOffScreenDirective, RemoveDuplicatesDirective],
    template: `
        <footer *ngIf="!(pSvc.loading$ | async)" appFooterOffScreen removeDuplicates class="grid pt-16 pb-12 bg-accent-800 w-full">
            <div class="container mx-auto px-6">
                <div class="grid grid-cols-1 xl:grid-cols-2 gap-12 w-full max-xl:max-w-full">
                    <div class="min-w-80 w-[660px] max-xl:max-w-full">
                        <div class="grid max-w-full w-[160px]">
                            <div class="grid w-[130px]">
                                <img [src]="section?.logo" class="object-contain aspect-[3.58] w-[130px]" alt="Equipio Logo" />
                            </div>
                        </div>
                        <nav class="grid grid-flow-col auto-cols-max gap-4 xl:gap-12 mt-8 w-full text-base font-semibold text-accent-200 max-xl:grid-flow-row max-xl:max-w-full">
                            <a *ngFor="let link of section?.navLinks" [href]="link.url" class="grid grid-flow-col gap-2 items-center hover:text-white transition">
                                {{ link.text }}
                            </a>
                        </nav>
                    </div>
<!--                    <div class="min-w-60 md:w-[460px] justify-self-end max-md:justify-self-start">-->
<!--                        <div class="text-sm font-medium leading-none text-white">-->
<!--                            {{ section?.contactMessage }}-->
<!--                        </div>-->
<!--                        <form [formGroup]="emailForm" (ngSubmit)="contactUs()">-->
<!--                            <div class="grid gap-3 mt-4 w-full text-base md:grid-cols-[1fr,auto]">-->
<!--                                <input-->
<!--                                    type="email"-->
<!--                                    placeholder="Enter your email"-->
<!--                                    formControlName="email"-->
<!--                                    class="px-4 py-3 w-full bg-white rounded-xl border border-solid shadow-sm border-gray-300 text-gray-700"-->
<!--                                    [class.border-red-500]="emailForm.get('email')?.invalid && emailForm.get('email')?.touched"-->
<!--                                />-->
<!--                                <button-->
<!--                                    type="submit"-->
<!--                                    [disabled]="submitting"-->
<!--                                    class="w-full md:w-auto px-5 py-3 font-semibold text-white bg-accent-700 rounded-xl shadow-sm border-[rgba(255,255,255,0.12)] transition">-->
<!--                                    {{ section?.contactButton }}-->
<!--                                </button>-->
<!--                            </div>-->
<!--                        </form>-->
<!--                    </div>-->
                    <div class="min-w-60 xl:w-[460px] justify-self-end max-xl:justify-self-start">
                        <div class="text-sm font-medium leading-none text-white">
                            {{ section?.contactMessage }}
                        </div>
                        <a [href]="'/contact-us'" class="mt-4 w-full xl:w-auto px-5 py-3 font-semibold text-white bg-accent-700 hover:bg-accent-600 rounded-xl shadow-sm border-[rgba(255,255,255,0.12)] transition text-center block">
                            {{ section?.contactButton || 'Contact Us' }}
                        </a>
                    </div>
                </div>
            </div>
            <div class="mt-16 container px-6 mx-auto text-base text-accent-300">
                <div class="grid grid-cols-2 gap-8 items-center pt-8 w-full border-t border-accent-600 max-xl:max-w-full max-xl:grid-cols-1">
                    <div>{{ section?.copyright }}</div>
                    <nav class="grid grid-flow-col auto-cols-max gap-6 whitespace-nowrap justify-self-end max-xl:justify-self-start">
                        <a *ngFor="let link of section?.footerLinks" [href]="link.url" class="hover:text-white transition">{{ link.text }}</a>
                    </nav>
                </div>
            </div>
        </footer>
    `
})
export class EqipNewFooter implements OnChanges {
    @Input() page: Page;
    @Input() section: any;

    emailForm: FormGroup;
    emailSuccess: boolean = false;
    submitting: boolean = false;

    constructor(private fb: FormBuilder, private cSvc: ClientService, private aSvc: AnalyticsService,public pSvc: PageService) {
        // this.emailForm = this.fb.group({
        //     email: ['', [Validators.required, Validators.email]]
        // });
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.section || this.page?.footer?.content?.sections?.length) {
            this.section = this.section || this.page?.footer.content.sections[0];
        }
    }
    // async contactUs() {
    //     if (this.emailForm.valid && !this.submitting) {
    //         this.submitting = true;
    //         const emailData = { email: this.emailForm.value.email };
    //
    //         try {
    //             await this.cSvc.callAPI('/contact/signup', 'post', emailData);
    //             await this.aSvc?.track('ga', 'generate_lead', { event_label: 'subscribe_clicked' });
    //             await this.aSvc?.track('mixpanel', 'Submitted Contact Form');
    //             await this.aSvc?.track('ga', 'generate_lead', { event_label: 'subscribe_success' });
    //             await this.aSvc?.track('facebook', 'Lead');
    //             await this.aSvc?.track('mixpanel', 'Form Submission', emailData);
    //
    //             this.emailSuccess = true;
    //             setTimeout(() => this.emailSuccess = false, 3000);
    //             this.emailForm.reset();
    //         } catch (error) {
    //             console.error("Error subscribing:", error);
    //         } finally {
    //             this.submitting = false;
    //         }
    //     } else {
    //         this.emailForm.markAllAsTouched();
    //     }
    // }
}
