import {Component, OnInit} from '@angular/core';
import {SharedService} from "../services/shared.service";
import {EqipNavHeader} from "../_components/header/eqip-nav-header";
import {AsyncPipe} from "@angular/common";
import {EqipFooter} from "../_components/footer/eqip-footer";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {PageService} from "@library/shared/_services/page.service";
import {PolicyComponent} from "../_components/policy/policy.component";

@Component({
    selector: 'privacy-policy',
    standalone: true,
    imports: [
        EqipNavHeader,
        AsyncPipe,
        EqipFooter,
        PolicyComponent
    ],
    template: `
        <eqip-nav-header></eqip-nav-header>
        <policy [class]="'mt-28 sm:px-10'" [documents]="document"></policy>
    `
})

export class PrivacyPolicyPage implements OnInit {
    document:string[]=['Privacy Policy']
    constructor(
        private pSvc: PageService,
    ) {}

    ngOnInit() {
        this.pSvc.titleService.setTitle(`Equipio - Privacy Policy`);
    }
}
