import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from "@angular/common";
import { IconsComponent } from "@library/shared/icons/icons.component";

@Component({
    selector: 'app-layout-toggle',
    standalone: true,
    imports: [CommonModule, IconsComponent],
    template: `
        <div class="bg-white text-lg text-gray-800 leading-none inline-flex border border-gray-300 p-0.5">
            <button (click)="toggleLayout('grid')" [class]="gridButtonClasses" id="grid">
                <icon name="heroicon-solid-squares" class="w-5 text-black"></icon>
            </button>
            <button (click)="toggleLayout('list')" [class]="listButtonClasses" id="list">
                <icon name="heroicon-outline-list-bullet" class="w-5 text-black"></icon>
            </button>
        </div>
    `,
})
export class LayoutToggleComponent {
    @Output() layoutChanged = new EventEmitter<string>();
    isGrid = true;

    get gridButtonClasses() {
        return `w-10 h-10 flex items-center justify-center transition-colors duration-200 ease-in focus:outline-none cursor-pointer border-r border-gray-300 ${
            this.isGrid ? 'bg-gray-200' : 'bg-white'
        }`;
    }

    get listButtonClasses() {
        return `w-10 h-10 flex items-center justify-center transition-colors duration-200 ease-in focus:outline-none cursor-pointer ${
            !this.isGrid ? 'bg-gray-200' : 'bg-white'
        }`;
    }

    toggleLayout(layout: string) {
        this.isGrid = (layout === 'grid');
        this.layoutChanged.emit(this.isGrid ? 'grid' : 'list');
    }
}
