import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[appFooterOffScreen]'
})
export class FooterOffScreenDirective implements AfterViewInit {

    constructor(private el: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit() {
        this.renderer.addClass(this.el.nativeElement, 'mt-[100vh]');

        setTimeout(() => {
            this.renderer.removeClass(this.el.nativeElement, 'mt-[100vh]');
        }, 1000);
    }
}
