import {ChangeDetectorRef, Component, HostListener, Input, OnInit} from '@angular/core';
import {Contact, Page} from '@nxt/model-core';
import {CommonModule} from '@angular/common';
import {NavigationEnd, Router, RouterModule} from '@angular/router';
import {ClientService} from '@library/shared/_services/client.service';
import {SlideSubmenuComponent} from "./submenu.component";
import {filter, firstValueFrom} from 'rxjs';
import {EqipCartService} from '../../services/eqip.cart.service';
import {ConsumerAccountService} from "@library/consumer/_services/consumer.account.service";
import {PageService} from "@library/shared/_services/page.service";
import {IconsComponent} from "@library/shared/icons/icons.component";
import {SharedService} from "../../services/shared.service";
import { takeUntil} from "rxjs/operators";
import {OnDestroyPage} from "../../../../../_library/shared/_inherited/ondestroy.page";


@Component({
    selector: 'eqip-nav-header',
    standalone: true,
    imports: [
        CommonModule, RouterModule, SlideSubmenuComponent, IconsComponent,
    ],
    template: `
        <header [ngClass]="{'bg-black text-white': isNavbarBlack, 'bg-white text-black': !isNavbarBlack}"
                class='fixed top-0 left-0 z-50 w-full bg-white shadow-lg'>
            <div class='flex flex-wrap items-center px-6 pt-2 pb-1 relative shadow-lg'>
                <div class="flex justify-between items-center w-full">
                    <a href="javascript:void(0)" class="flex items-center h-full lg:hidden">
                        <ng-container *ngIf="!isNavbarBlack">
                            <img [src]="imageUrl" routerLink="/" class="object-contain h-10 lg:h-14" alt="logo"/>
                        </ng-container>
                    </a>
                    <div class='flex items-center'>
                        <a aria-label="Checkout" routerLink="/u/checkout" class="relative lg:hidden mt-2 mr-4">
                            <div class="relative inline-block">
                                <icon strokeWidth="1.6" name="heroicon-outline-shopping-cart"
                                      [ngClass]="{'text-white': isNavbarBlack, 'text-black': !isNavbarBlack}"
                                      class="w-10 h-10"></icon>
                                <span *ngIf="(ecSvc.cartDocument$|async)?.checkout?.items?.length"
                                      class="notification-badge">
                                {{ (ecSvc.cartDocument$|async)?.checkout?.items?.length }}
                            </span>
                            </div>
                        </a>

                        <a *ngIf="(cSvc.u$ | async)?.isAnonymous" aria-label="Login" class="lg:hidden"
                           routerLink="/u/account">
                            <button
                                [ngClass]="{'bg-white text-black': isNavbarBlack, 'bg-black text-white': !isNavbarBlack}"
                                class="px-4 py-2 rounded">Login
                            </button>
                        </a>

                        <a *ngIf="!(cSvc.u$ | async)?.isAnonymous" aria-label="Account" class="lg:hidden">
                            <icon strokeWidth="1.6"
                                  [ngClass]="{'text-white': isNavbarBlack, 'text-black': !isNavbarBlack}"
                                  name="heroicon-outline-user-circle"
                                  class="w-10 h-10" (click)="toggleAccountMenu()"></icon>
                        </a>
                        <div *ngIf="isAccountMenuOpen"
                             [ngClass]="{
                                'bg-black text-white': isNavbarBlack,
                                'bg-white text-black': !isNavbarBlack
                             }"
                             [ngStyle]="{'top': isNavbarBlack ? '0rem' : '0rem'}"
                             class="absolute left-0 w-full z-40 mt-14 shadow-lg transition-opacity duration-300 ease-in-out">
                            <ul class="py-2 px-4">
                                <li *ngIf="contact?.verified"><a routerLink="/u/account" class="block py-2">RFQ History</a></li>
                                <li *ngIf="contact?.verified && contact?.type==='vendor'"><a routerLink="/u/account/vendor" class="block py-2">Vendor RFQ History</a></li>
                                <li><a routerLink="/u/account/profile" class="block py-2">Profile</a></li>
                                <li><a routerLink="/u/account/support" class="block py-2">Support</a></li>
                                <li><a class="block py-2 cursor-pointer" (click)="signOut()">Log Out</a></li>
                            </ul>
                        </div>

                        <button (click)="toggleMenu()" class='ml-2 lg:hidden'>
                            <icon strokeWidth="1.6" name="heroicon-outline-menu" [class.hidden]="isMenuOpen"
                                  [ngClass]="{'text-white': isNavbarBlack, 'text-black': !isNavbarBlack}"
                                  class="w-10 h-10"></icon>
                            <icon strokeWidth="1.6" name="heroicon-outline-x" [class.hidden]="!isMenuOpen"
                                  [ngClass]="{'text-white': isNavbarBlack, 'text-black': !isNavbarBlack}"
                                  class="w-10 h-10"></icon>
                        </button>
                    </div>
                </div>
                <div [class.hidden]="!isMenuOpen"
                     class="lg:flex lg:items-center w-full categories-container">
                    <ul class='lg:flex lg:space-x-4 lg:space-y-0 space-y-2 left-bar'>
                        <a href='javascript:void(0)' class='hidden new-logo'>
                            <ng-container *ngIf="!isNavbarBlack">
                                <img routerLink="/" [src]="imageUrl" class="w-32 object-contain" alt="logo"/>
                            </ng-container>
                        </a>
                        <li class="relative group" (mouseover)="showDropdown('camera')"
                            (mouseout)="scheduleHideDropdown('camera')">
                            <div class="flex justify-between items-center w-176px">
                                <a aria-label="Camera" routerLink="/camera"
                                   class="block border-b-2 font-medium border-transparent hover:border-b-2 hover:border-black transition duration-300 ease-in-out"
                                   routerLinkActive="active">
                                    Camera
                                </a>
                                <button (click)="toggleSubMenu('camera')" class="lg:hidden hover:bg-gray-200">
                                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                         fill="currentColor">
                                        <path fill-rule="evenodd"
                                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                </button>
                            </div>
                            <div *ngIf="isSubMenuOpen" class="relative bg-white">
                                <!--                                This is the only place where it can slide in. It takes in the active submenu array and does the sliding from inside this component-->
                                <app-slide-submenu [isOpen]="isSubMenuOpen" [items]="activeSubmenuItems"
                                                   [textColor]="isNavbarBlack ? 'white' : 'black'"
                                                   [bgColor]="isNavbarBlack ? 'black' : 'white'"
                                                   (closeSubMenuRequest)="closeSubMenu()"></app-slide-submenu>
                            </div>
                            <div class="hidden lg:block">
                                <div *ngIf="dropdownVisibility.camera"
                                     [ngClass]="{'hidden': !dropdownVisibility.camera}"
                                     class="absolute left-0 bg-black shadow-lg z-50 px-6 transition duration-300 ease-in-out border-2 border-white rounded w-[15rem] dropdown">
                                    <ul class="py-2">
                                        <li><a href="#" routerLink="/camera/bodies"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white"> <span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            Cameras
                                        </span></a></li>
                                        <li><a href="#" routerLink="/camera/camera-accessories"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white"><span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            Camera Accessories
                                        </span></a></li>
                                        <li><a href="#" routerLink="/camera/camera-support"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white"><span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            Camera Support
                                        </span></a></li>
                                        <li><a href="#" routerLink="/camera/lenses"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white"><span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            Lenses
                                        </span></a>
                                        </li>
                                        <li><a href="#" routerLink="/camera/lens-accessories"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white"><span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                           Lens Accessories
                                        </span></a></li>
                                        <li><a href="#" routerLink="/camera/camera-storage"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white"><span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            Camera Storage
                                        </span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li class="relative group" (mouseover)="showDropdown('audio')"
                            (mouseout)="scheduleHideDropdown('audio')">
                            <div class="flex justify-between items-center w-176px">
                                <a aria-label="Audio" routerLink="/audio"
                                   class="block border-b-2 font-medium border-transparent hover:border-b-2 hover:border-black transition duration-300 ease-in-out"
                                   routerLinkActive="active">
                                    Audio
                                </a>
                                <button (click)="toggleSubMenu('audio')" class="lg:hidden hover:bg-gray-200">
                                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                         fill="currentColor">
                                        <path fill-rule="evenodd"
                                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="hidden lg:block">
                                <div *ngIf="dropdownVisibility.audio" [ngClass]="{'hidden': !dropdownVisibility.audio}"
                                     class="absolute left-0 bg-black shadow-lg z-50 px-6 transition duration-300 ease-in-out border-2 border-white w-[15rem] top-5 rounded dropdown">
                                    <ul class="py-2">
                                        <li><a href="#" routerLink="/audio/microphones"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white">
                                        <span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            Microphone
                                        </span></a></li>
                                        <li><a href="#" routerLink="/audio/audio-accessories"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white"> <span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            Audio Accessories
                                        </span></a></li>
                                        <li><a href="#" routerLink="/audio/speakers"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white"> <span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            Speakers
                                        </span></a></li>
                                        <li><a href="#" routerLink="/audio/live-audio"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white"> <span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            Live Audio
                                        </span></a></li>
                                        <li><a href="#" [routerLink]="['/audio', 'field-mixers&recorders']"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white"> <span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            Field Mixers/Recorders
                                        </span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li class="relative group" (mouseover)="showDropdown('lighting')"
                            (mouseout)="scheduleHideDropdown('lighting')">
                            <div class="flex justify-between items-center w-176px">
                                <a aria-label="Lighting" routerLink="/lighting"
                                   class="block border-b-2 border-transparent hover:border-b-2 hover:border-black transition duration-300 ease-in-out"
                                   routerLinkActive="active">
                                    Lighting
                                </a>
                                <button (click)="toggleSubMenu('lighting')" class="lg:hidden hover:bg-gray-200">
                                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                         fill="currentColor">
                                        <path fill-rule="evenodd"
                                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="hidden lg:block">
                                <div [ngClass]="{'hidden': !dropdownVisibility.lighting}"
                                     class="absolute left-0 bg-black shadow-lg z-50 px-6 transition duration-300 ease-in-out border-2 border-white w-[15rem] top-5 rounded dropdown">
                                    <ul class="py-2">
                                        <li><a href="#" routerLink="/lighting/led"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white">
                                        <span class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            LED
                                        </span></a></li>
                                        <li><a href="#" routerLink="/lighting/creative-led"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white"> <span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            Creative LED
                                        </span></a></li>
                                        <li><a href="#" routerLink="/lighting/tungsten"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white"> <span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            Tungsten
                                        </span></a></li>
                                        <li><a href="#" routerLink="/lighting/HMI"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white"> <span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            HMI
                                        </span></a></li>
                                        <li><a href="#" routerLink="/lighting/lighting-accessories"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white"> <span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            Lighting Accessories
                                        </span></a></li>
                                        <li><a href="#" routerLink="/lighting/static-lights"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white"> <span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            Static Lights
                                        </span></a></li>
                                        <li><a href="#" routerLink="/lighting/static-lights-(stage)"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white"> <span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            Static Lights (Stage)
                                        </span></a></li>
                                        <li><a href="#" routerLink="/lighting/moving-lights-(stage)"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white"> <span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            Moving Lights (Stage)
                                        </span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li class="relative group" (mouseover)="showDropdown('mobile_truck_units')"
                            (mouseout)="scheduleHideDropdown('mobile_truck_units')">
                            <div class="flex justify-between items-center w-176px">
                                <a aria-label="Mobile Truck Units" routerLink="/mobile-truck-units"
                                   class="block border-b-2 font-medium border-transparent hover:border-b-2 hover:border-black transition duration-300 ease-in-out"
                                   routerLinkActive="active">
                                    Mobile Truck Units
                                </a>
                                <button (click)="toggleSubMenu('mobile_truck_units')"
                                        class="lg:hidden hover:bg-gray-200">
                                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                         fill="currentColor">
                                        <path fill-rule="evenodd"
                                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="hidden lg:block">
                                <div *ngIf="dropdownVisibility.mobile_truck_units"
                                     [ngClass]="{'hidden': !dropdownVisibility.mobile_truck_units}"
                                     class="absolute left-0 bg-black shadow-lg z-50 px-6 transition duration-300 ease-in-out border-2 border-white w-[15rem] top-5 rounded dropdown">
                                    <ul class="py-2">
                                        <li><a href="#" routerLink="/mobile-truck-units/mobile-production-units"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white">
                                        <span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            Mobile Production Units
                                        </span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li class="relative group" (mouseover)="showDropdown('grip')" (mouseout)="scheduleHideDropdown('grip')">
                            <div class="flex justify-between items-center w-176px">
                                <a aria-label="Grip" routerLink="/grip"
                                   class="block border-b-2 font-medium border-transparent hover:border-b-2 hover:border-black transition duration-300 ease-in-out"
                                   routerLinkActive="active">
                                    Grip
                                </a>
                                <button (click)="toggleSubMenu('grip')" class="lg:hidden hover:bg-gray-200">
                                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="hidden lg:block">
                                <div *ngIf="dropdownVisibility.grip" [ngClass]="{'hidden': !dropdownVisibility.grip}"
                                     class="absolute left-0 bg-black shadow-lg z-50 px-6 transition duration-300 ease-in-out border-2 border-white w-[15rem] top-5 rounded dropdown">
                                    <ul class="py-2">
                                        <li>
                                            <a routerLink="/grip/hardware" routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white">
                                            <span class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                                Hardware
                                            </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/grip/stands" routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white">
                                            <span class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                                Stands
                                            </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/grip/dollies&jibs&cranes" routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white">
                                            <span class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                                Dollies, Jibs, and Cranes
                                            </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/grip/bounce-boards-and-reflectors" routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white">
                                            <span class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                                Bounce Boards and Reflectors
                                            </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/grip/rigging" routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white">
                                            <span class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                                Rigging
                                            </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/grip/flags&scrims&silks" routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white">
                                            <span class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                                Flags, Scrims, and Silks
                                            </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/grip/overheads" routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white">
                                            <span class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                                Overheads
                                            </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/grip/filters&gels" routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white">
                                            <span class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                                Filters/Gels
                                            </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/grip/ladders-and-carts" routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white">
                                            <span class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                                Ladders and Carts
                                            </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/grip/sandbags-and-shot-bags" routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white">
                                            <span class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                                Sandbags and Shot Bags
                                            </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/grip/boxes" routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white">
                                            <span class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                                Boxes
                                            </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>

                        <li class="relative group" (mouseover)="showDropdown('electric')"
                            (mouseout)="scheduleHideDropdown('electric')">
                            <div class="flex justify-between items-center w-176px">
                                <a aria-label="Electric" routerLink="/electric"
                                   class="block border-b-2 font-medium border-transparent hover:border-b-2 hover:border-black transition duration-300 ease-in-out"
                                   routerLinkActive="active">
                                    Electric
                                </a>
                                <button (click)="toggleSubMenu('electric')"
                                        class="lg:hidden hover:bg-gray-200">
                                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                         fill="currentColor">
                                        <path fill-rule="evenodd"
                                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="hidden lg:block">
                                <div *ngIf="dropdownVisibility.electric"
                                     [ngClass]="{'hidden': !dropdownVisibility.electric}"
                                     class="absolute left-0 bg-black shadow-lg z-50 px-6 transition duration-300 ease-in-out border-2 border-white w-[15rem] top-5 rounded dropdown">
                                    <ul class="py-2">
                                        <li><a href="#" routerLink="/electric/generators"
                                               routerLinkActive="border-b-2 border-white"
                                               class="block px-4 py-2 transition duration-800 ease-in-out text-white">
                                        <span
                                            class="border-b-2 border-transparent hover:border-white transition-all ease-in-out duration-300">
                                            Generators
                                        </span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>


                        <li class="relative group"
                            (mouseover)="showDropdown('video')"
                            (mouseout)="scheduleHideDropdown('video')">
                            <div class="flex justify-between items-center w-176px">
                                <a aria-label="Video"

                                   class="block border-b-2 font-medium border-transparent hover:border-b-2 hover:border-black transition duration-300 ease-in-out"
                                   routerLinkActive="active"
                                   [attr.title]="!isEnabled ? 'Coming Soon' : null"
                                   [class.opacity-50]="!isEnabled"
                                   [class.cursor-not-allowed]="!isEnabled">
                                    Video
                                </a>
                                <button
                                    class="lg:hidden hover:bg-gray-200"
                                    [disabled]="!isEnabled">
                                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                         fill="currentColor">
                                        <path fill-rule="evenodd"
                                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                </button>
                            </div>
                        </li>

                        <li class="relative group"
                            (mouseover)="showDropdown('production_supplies')"
                            (mouseout)="scheduleHideDropdown('production_supplies')">
                            <div class="flex justify-between items-center w-176px">
                                <a aria-label="Production Supplies"

                                   class="block border-b-2 font-medium border-transparent hover:border-b-2 hover:border-black transition duration-300 ease-in-out"
                                   routerLinkActive="active"
                                   [attr.title]="!isEnabled ? 'Coming Soon' : null"
                                   [class.opacity-50]="!isEnabled"
                                   [class.cursor-not-allowed]="!isEnabled">
                                    Production Supplies
                                </a>
                                <button
                                    class="lg:hidden hover:bg-gray-200"
                                    [disabled]="!isEnabled">
                                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                         fill="currentColor">
                                        <path fill-rule="evenodd"
                                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                </button>
                            </div>
                        </li>
                        <li class="relative group lg:hidden pb-4">
                            <!--                            <div class="flex justify-between items-center w-176px">-->
                            <!--                                <a aria-label="Production Supplies" routerLink="/production-supplies"-->
                            <!--                                   class="block border-b-2 border-transparent hover:border-b-2 hover:border-black transition duration-300 ease-in-out"-->
                            <!--                                   routerLinkActive="active">-->
                            <!--                                    Equipio Main Menu-->
                            <!--                                </a>-->
                            <!--                                <button (click)="toggleSubMenu('main_menu')"-->
                            <!--                                        class="hover:bg-gray-200">-->
                            <!--                                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"-->
                            <!--                                         fill="currentColor">-->
                            <!--                                        <path fill-rule="evenodd"-->
                            <!--                                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"-->
                            <!--                                              clip-rule="evenodd"/>-->
                            <!--                                    </svg>-->
                            <!--                                </button>-->
                            <!--                            </div>-->
                            <!--                        </li>-->
                    </ul>
                    <a aria-label="Home" routerLink="/"
                       class='m-auto absolute lg:left-2/4 lg:-translate-x-1/2 hidden lg:block'>
                        <ng-container *ngIf="!isNavbarBlack">
                            <img [src]="imageUrl" routerLink="/" class="w-32 mb-2 object-contain custom-logo"
                                 alt="logo"/>
                        </ng-container>
                    </a>
                    <ul class='lg:flex lg:justify-center lg:items-center lg:space-x-4 lg:space-y-0 space-y-2 ml-auto'>
                        <li class="hidden relative lg:block">
                            <a aria-label="Checkout" routerLink="/u/checkout" class="relative inline-block mt-2">
                                <div class="relative inline-block">
                                    <icon strokeWidth="1.6" name="heroicon-outline-shopping-cart"
                                          [ngClass]="{'text-white': isNavbarBlack, 'text-black': !isNavbarBlack}"
                                          class="w-10 h-10"></icon>
                                    <span *ngIf="(ecSvc.cartDocument$|async)?.checkout?.items?.length"
                                          class="notification-badge">
                {{ (ecSvc.cartDocument$|async)?.checkout?.items?.length }}
            </span>
                                </div>
                            </a>
                        </li>
                        <!--                        <li class="lg:hidden relative">-->
                        <!--                            <a aria-label="Checkout" routerLink="/u/checkout" class="relative">-->
                        <!--                                <img src="../../../assets/icons/cart.svg" class="w-12 h-12" alt="cart">-->
                        <!--                                <span *ngIf="cartItemCount > 0"-->
                        <!--                                      class="absolute top-0 left-0 bg-red-500 text-white rounded-full text-xs w-6 h-6 flex items-center justify-center">-->
                        <!--            {{ cartItemCount }}-->
                        <!--        </span>-->
                        <!--                            </a>-->
                        <!--                        </li>-->

                    </ul>
                    <div class="relative ml-7 lg:inline-block">
                        <div class="hidden lg:block">
                            <a *ngIf="(cSvc.u$ | async)?.isAnonymous" aria-label="Login" routerLink="/u/account">
                                <button
                                    [ngClass]="{'bg-white text-black': isNavbarBlack, 'bg-black text-white': !isNavbarBlack}"
                                    class="px-4 py-2 rounded">Login
                                </button>
                            </a>
                            <div *ngIf="!(cSvc.u$ | async)?.isAnonymous"
                                 (mouseenter)="toggleLargeScreenMenu(true)"
                                 (mouseleave)="toggleLargeScreenMenu(false)">
                                <a aria-label="Account">
                                    <icon strokeWidth="1.6"
                                          [ngClass]="{'text-white': isNavbarBlack, 'text-black': !isNavbarBlack}"
                                          name="heroicon-outline-user-circle"
                                          class="w-11"></icon>
                                </a>
                                <div
                                    class="absolute right-0 mt-4 w-48 bg-black shadow-md border-2 border-white rounded z-10"
                                    *ngIf="isLargeScreenMenuOpen">
                                    <ul class="text-white">
                                        <li *ngIf="contact && contact?.verified">
                                            <a routerLink="/u/account" class="block px-4 py-2">
                                                <span class="border-b-2 border-transparent hover:border-white">RFQ History</span>
                                            </a>
                                        </li>
                                        <li *ngIf="contact && contact?.verified && contact?.type==='vendor'"><a
                                            routerLink="/u/account/vendor" class="block px-4 py-2">
                                            <span
                                                class="border-b-2 border-transparent hover:border-white">Vendor RFQ History</span></a>
                                        </li>

                                        <li><a routerLink="/u/account/profile" class="block px-4 py-2">
                                            <span
                                                class="border-b-2 border-transparent hover:border-white">Profile</span></a>
                                        </li>
                                        <li><a routerLink="/u/account/support" class="block px-4 py-2">
                                            <span
                                                class="border-b-2 border-transparent hover:border-white">Support</span></a>
                                        </li>
                                        <li><a class="block px-4 py-2 cursor-pointer" (click)="signOut()">
                                            <span
                                                class="border-b-2 border-transparent hover:border-white">Log Out</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <icon strokeWidth="1.6" name="heroicon-outline-x"
                                  *ngIf="isLargeScreenMenuOpen && (cSvc.u$ | async)?.isAnonymous"
                                  [ngClass]="{'text-white': isNavbarBlack, 'text-black': !isNavbarBlack}"
                                  class="w-7 h-7" (click)="toggleLargeScreenMenu(false)"></icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="h-1 bg-accent" [style.width]="scrollProgress + '%'"></div>
        </header>

    `,
    styles: [
        `
            //had to do this because for some reason my tailwind positioning worked for Chrome and Safari, But on firefox it did not.
            //I would get it to work on firefox it would break chrome. So I finally got this solution to work for both.
            .notification-badge {
                position: absolute;
                top: 2px;
                right: 3px;
                transform: translate(50%, -50%);
                background-color: rgb(239, 68, 68);
                color: white;
                border-radius: 50%;
                width: 1.5rem;
                height: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.75rem;
                line-height: 1.5rem;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
                pointer-events: none;
            }
        `

    ]
})
export class EqipNavHeader extends OnDestroyPage implements OnInit {
    @Input() page: Page;
    @Input() section: any;
    @Input() hideBtn: boolean;
    @Input() u: any;
    @Input() isEnabled: boolean = false;
    isMenuOpen = false;
    isAccountMenuOpen = false;
    isLargeScreenMenuOpen: boolean = false;
    isSubMenuOpen = false;
    dropdownVisibility: { [key: string]: boolean } = {
        camera: false,
        audio: false,
        mobile_truck_units: false,
        video: false,
        lighting: false,
        grip: false,
        electric: false,
        production_supplies: false,
    };
    scrollProgress: number = 0;
    private menuCloseTimeout: any;
    isLoggedIn: boolean = false;
    isOnHomePage: boolean = false;
    isNavbarBlack: boolean = false;
    isQuarterScrolled = false;
    contact: Contact;
    //active submenu when you click on a menu button in responsive mode. Get's assigned when you toggleSubMenu
    activeSubmenuItems: Array<{ label: string, link: (string | string[])}> = [];
    public submenuAnimationState: string = 'closed';
    private hideDropdownTimeouts: { [key: string]: any } = {};

    constructor(
        public acctSvc: ConsumerAccountService,
        public pSvc: PageService,
        public cSvc: ClientService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        public ecSvc: EqipCartService,
        private changeDetectorRef: ChangeDetectorRef,
        private sSvc: SharedService
    ) {
        super()
    }

    async ngOnInit(): Promise<void> {
        this.checkCurrentRoute();
        this.cSvc.u$
            .pipe(takeUntil(this.d$))
            .subscribe(async (user) => {
                if (user && !user?.isAnonymous) {
                    this.sSvc.contact$
                        .pipe(takeUntil(this.d$))
                        .subscribe(contact => {
                            if (contact) {
                                this.contact = contact;
                                this.cdr.detectChanges();
                            } else {
                                setTimeout(async () => {
                                    const fetchedContact = await firstValueFrom(this.sSvc.contact$);
                                    if (fetchedContact) {
                                        this.contact = fetchedContact;
                                        this.cdr.detectChanges();
                                    }
                                }, 3100);
                            }
                        });
                }
            });



        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd),takeUntil(this.d$))
            .subscribe(() => this.checkCurrentRoute());

    }

    //toggles hamburger menu and shuts off any submenu's back to false (for when a user shrinks their window and a menu is still open).
    toggleMenu(): void {
        this.isMenuOpen = !this.isMenuOpen;
        this.isAccountMenuOpen = false;
        this.isSubMenuOpen = false;
    }

    toggleAccountMenu(): void {
        this.isAccountMenuOpen = !this.isAccountMenuOpen;
        this.isMenuOpen = false;
    }

    toggleLargeScreenMenu(open: boolean): void {
        if (open) {
            clearTimeout(this.menuCloseTimeout);
            this.isLargeScreenMenuOpen = true;
        } else {
            this.menuCloseTimeout = setTimeout(() => {
                this.isLargeScreenMenuOpen = false;
            }, 1000);
        }
    }

    //give you the submenu slide from right when you are in responsive mode.
    toggleSubMenu(category: string): void {
        //flips the boolean for the submenu to slide in.
        this.isSubMenuOpen = !this.isSubMenuOpen;
        if (this.isSubMenuOpen) {
            //depending on the argument grabs the corresponding submenu array and assigns it as active. Otherwise it blanks it out.
            this.activeSubmenuItems = this.categoriesWithSubmenus[category];
        } else {
            this.activeSubmenuItems = [];
        }
        this.changeDetectorRef.detectChanges();
    }

    closeSubMenu(): void {
        this.isSubMenuOpen = false;
    }

    submenuState: { [key: string]: boolean } = {
        camera: false,
        audio: false,
        video: false,
        lighting: false,
        grip: false,
        electric: false,
        production_supplies: false,
        mobile_truck_units: false,
    };

    categoriesWithSubmenus: { [key: string]: Array<{ label: string, link: (string | string[]) }> } = {
        camera: [
            {label: 'Cameras', link: '/camera/bodies'},
            {label: 'Camera Accessories', link: '/camera/camera-accessories'},
            {label: 'Camera Support', link: '/camera/camera-support'},
            {label: 'Lenses', link: '/camera/lenses'},
            {label: 'Lens Accessories', link: '/camera/lens-accessories'},
            {label: 'Camera Storage', link: '/camera/camera-storage'},
        ],
        audio: [
            {label: 'Microphone', link: '/audio/microphones'},
            {label: 'Audio Accessories', link: '/audio/audio-accessories'},
            {label: 'Live Audio', link: '/audio/live-audio'},
            {label: 'Speakers', link: '/audio/speakers'},
            {label: 'Field Mixers/Recorders', link: ['/audio', 'field-mixers&recorders']},
        ],
        video: [
            {label: 'Media Servers', link: '/video/media-servers'},
            {label: 'Multi-Format Image Processing', link: '/video/multi-format-image-processing'},
            {label: 'RF Video', link: '/video/rf-video'},
            {label: 'Signal Processing & Distribution', link: '/video/signal-processing-distribution'},
            {label: 'Video Production Switchers and Routers', link: '/video/video-production-switchers-routers'},
            {label: 'Video Recorders and Players', link: '/video/video-recorders-players'},
        ],
        lighting: [
            {label: 'LED', link: '/lighting/led'},
            {label: 'Creative LED', link: '/lighting/creative-led'},
            {label: 'Tungsten', link: '/lighting/tungsten'},
            {label: 'HMI', link: '/lighting/HMI'},
            {label: 'Lighting Accessories', link: '/lighting/lighting-accessories'},
            {label: 'Static Lights', link: '/lighting/static-lights'},
            {label: 'Static Lights (Stage)', link: '/lighting/static-lights-(stage)'},
            {label: 'Moving Lights (Stage)', link: '/lighting/moving-lights-(stage)'},
        ],
        grip: [
            {label: 'Hardware', link: '/grip/hardware'},
            {label: 'Stand', link: '/grip/stands'},
            {label: 'Dollies and Jibs', link: '/grip/dollies&jibs&cranes'},
            {label: 'Bounce Boards and Reflectors', link: '/grip/bounce-boards-and-reflectors'},
            {label: 'Rigging', link: '/grip/rigging'},
            {label: 'Flags/Scrims/Silks', link: '/grip/flags&scrims&silks'},
            {label: 'Overheads', link: '/grip/overheads'},
            {label: 'Filter/Gels', link: '/grip/filters&gels'},
            {label: 'Ladders and Carts', link: '/grip/ladders-and-carts'},
            {label: 'Sandbags/Shot Bags', link: '/grip/sandbags-and-shot-bags'},
            {label: 'Boxes', link: '/grip/boxes'},
        ],
        electric: [
            // {label: 'Extension Cords', link: '/electric/extensions-cords'},
            // {label: 'Breakout', link: '/electric/breakout'},
            // {label: 'Dimmer', link: '/electric/dimmer'},
            // {label: 'Adapters', link: '/electric/adapters'},
            {label: 'Generators', link: '/electric/generators'},
        ],
        production_supplies: [
            {label: 'Communications', link: '/production-supplies/communications'},
            {label: 'Tents, Tables, Chairs', link: '/production-supplies/tents-tables-chairs'},
            {label: 'Heating & Cooling', link: '/production-supplies/heating-cooling'},
            {label: 'Tools', link: '/production-supplies/tools'},
            {label: 'Effects', link: '/production-supplies/effects'},
            {label: 'Presentation/Projection', link: '/production-supplies/presentation-projection'},
        ],
        mobile_truck_units: [
            {label: 'Mobile Product Units', link: 'mobile-truck-units/mobile-production-units'}
        ],
        account: [
            // {label: 'RFQ History', link: '/u/account'},
            {label: 'profile', link: '/u/account/profile'},
            {label: 'Support', link: '/u/account/support'},
            {label: 'Log Out', link: '#',}
        ],
    };

    //how to show dropdown method
    showDropdown(dropdownId: string): void {
        // Close all dropdowns first
        Object.keys(this.dropdownVisibility).forEach(key => {
            this.dropdownVisibility[key] = false;
        });
        // Clear any existing timeouts
        clearTimeout(this.hideDropdownTimeouts[dropdownId]);
        // Show the requested dropdown
        this.dropdownVisibility[dropdownId] = true;
    }

    //manage closing the dropdown menu after the delay. This is so you can make it over to the submenu before it closes.
    scheduleHideDropdown(dropdownId: string): void {
        this.hideDropdownTimeouts[dropdownId] = setTimeout(() => {
            this.dropdownVisibility[dropdownId] = false;
            this.changeDetectorRef.detectChanges();
        }, 300);
    }


    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (window.innerWidth > 1024) {
            this.isMenuOpen = false;
            this.isSubMenuOpen = false;
            this.isAccountMenuOpen = false;
            Object.keys(this.dropdownVisibility).forEach(key => {
                this.dropdownVisibility[key] = false;
            });
        }
        if (window.innerWidth < 1024) {
            this.isLargeScreenMenuOpen = false;
        }
    }

    //rip lodash
    throttle(callback: Function, limit: number) {
        let waiting = false;
        return function () {
            if (!waiting) {
                callback.apply(this, arguments);
                waiting = true;
                setTimeout(function () {
                    waiting = false;
                }, limit);
            }
        };
    }

    //the fact that I have to use a throttle is just 😝😝😝😝
    //this is for the navbar progress bar under the bar. It find the height and then where you are from the top and calculates the percentage.
    @HostListener('window:scroll', ['$event'])
    onWindowScroll = this.throttle(() => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        this.scrollProgress = (scrollTop / height) * 100;

        const quarterPoint = height / 5.4;
        this.isQuarterScrolled = scrollTop > quarterPoint;

        this.updateNavbarAppearance();
    }, 5);

    get imageUrl() {
        // This checks if `section` is truthy and if `section.image` is truthy.
        // If not, it returns the fallback URL.
        // Not sure why I couldn't do this inline.
        return this.section?.image ?? '/assets/images/eqip-blue-logo.png';
    }

    //probably/definitely should use this

    signOut() {
        const viewportWidth = window.innerWidth;
        const modalWidth = viewportWidth < 768 ? '80vw' : '25vw';
        const modalHeight = viewportWidth < 768 ? '15vh !important' : '20vh !important'

        this.pSvc.alert$.next({
            title: 'Log Out?',
            message: 'Are you sure you want to log out?',
            buttons: [
                {label: 'Cancel', closeOnClick: true},
                {
                    label: 'Log Out',
                    class: 'btn-dark',
                    closeOnClick: true,
                    click: async () => {
                        await this.acctSvc.signOut();
                        if (this.router.url.startsWith('/u/account')) {
                            this.router.navigateByUrl('/account');
                        } else {
                            location.reload();
                        }
                    }
                }
            ],
            styles: {
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                'z-index': '1000',
                width: modalWidth,
                'min-height': `20vh !important`,
                'max-height':modalHeight,
                display: 'flex',
                flexDirection: 'column'
            },
        });
    }


    private checkCurrentRoute() {
        const currentRoute = this.router.url;
        this.isOnHomePage = currentRoute === '/';
        this.updateNavbarAppearance();
    }

    private updateNavbarAppearance() {
        if (this.isOnHomePage && !this.isQuarterScrolled) {
            this.isNavbarBlack = true;
        } else {
            this.isNavbarBlack = false;
        }
    }

}

