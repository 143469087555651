"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EqipProduct = exports.FuelType = exports.Phases = exports.Voltage = exports.Amperage = exports.StateAndProvinceAbbreviations = exports.categoryToTypes = exports.TruckCameraResolution = exports.TruckSize = exports.Environment = exports.ChipQuantity = exports.ScreenRatio = exports.Brightness = exports.Wattage = exports.PowerRequirement = exports.Style = exports.HoistCapacity = exports.DataCables = exports.Size = exports.SensorCoverage = exports.Type = exports.MediaType = exports.LensMount = exports.SensorSize = exports.RefreshRate = exports.FrameRate = exports.Category = exports.LumenRange = exports.Resolution = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
// Resolution Enum
var Resolution;
(function (Resolution) {
    Resolution["hd"] = "HD";
    Resolution["k2"] = "2K";
    Resolution["k4"] = "4K";
    Resolution["k5"] = "5K";
    Resolution["k6"] = "6K";
    Resolution["k8"] = "8K";
    Resolution["wuxga"] = "WUXGA";
    Resolution["xga"] = "XGA";
})(Resolution || (exports.Resolution = Resolution = {}));
// Lumen Enum
var LumenRange;
(function (LumenRange) {
    LumenRange["Range3kTo10k"] = "3K-10K";
    LumenRange["Range11kTo19k"] = "11K-19K";
    LumenRange["Range20kTo40k"] = "20K-40K";
})(LumenRange || (exports.LumenRange = LumenRange = {}));
// Categories Enum
var Category;
(function (Category) {
    Category["camera"] = "Camera";
    Category["audio"] = "Audio";
    Category["grip"] = "Grip";
    Category["lighting"] = "Lighting";
    Category["electric"] = "Electric";
    Category["productionSupplies"] = "Production Supplies";
    Category["video"] = "Video";
    Category["mobileTruckUnits"] = "Mobile Truck Units";
})(Category || (exports.Category = Category = {}));
// Frame Rate Enum
var FrameRate;
(function (FrameRate) {
    FrameRate["fps24"] = "24fps";
    FrameRate["fps25"] = "25fps";
    FrameRate["fps30"] = "30fps";
    FrameRate["fps48"] = "48fps";
    FrameRate["fps50"] = "50fps";
    FrameRate["fps60"] = "60fps";
    FrameRate["fps90"] = "90fps";
    FrameRate["fps100"] = "100fps";
    FrameRate["fps120"] = "120fps";
    FrameRate["fps180"] = "180fps";
    FrameRate["fps200"] = "200fps";
    FrameRate["fps240"] = "240fps";
})(FrameRate || (exports.FrameRate = FrameRate = {}));
var RefreshRate;
(function (RefreshRate) {
    RefreshRate["refresh1200"] = "1200";
    RefreshRate["refresh1920"] = "1920";
    RefreshRate["refresh3000"] = "3000";
})(RefreshRate || (exports.RefreshRate = RefreshRate = {}));
// Sensor Size Enum
var SensorSize;
(function (SensorSize) {
    SensorSize["fourThirds"] = "4/3\"";
    SensorSize["twoThirds"] = "2/3\"";
    SensorSize["fullFrame"] = "Full Frame";
    SensorSize["super35"] = "Super 35mm";
    SensorSize["aps_c"] = "APS-C";
    SensorSize["one"] = "1\"";
    SensorSize["oneThird"] = "1/3\"";
    SensorSize["oneHalf"] = "1/2\"";
    SensorSize["oneTwoAndHalf"] = "1/2.5\"";
    SensorSize["oneOneSeventh"] = "1/1.7\"";
    SensorSize["oneTwoEightSixth"] = "1/2.86\"";
})(SensorSize || (exports.SensorSize = SensorSize = {}));
// Lens Mount Enum
var LensMount;
(function (LensMount) {
    LensMount["ef"] = "EF";
    LensMount["rf"] = "RF";
    LensMount["b4"] = "B4";
    LensMount["pl"] = "PL";
    LensMount["other"] = "Other";
    LensMount["integratedLens"] = "Integrated Lens";
    LensMount["m43"] = "M43";
    LensMount["cs"] = "CS";
    LensMount["e_Mount"] = "E-Mount";
    LensMount["lpl"] = "LPL";
    LensMount["pv"] = "PV";
    LensMount["ef_S"] = "EF-S";
    LensMount["mft"] = "mft";
    LensMount["m"] = "M";
    LensMount["sp70"] = "SP70";
    LensMount["fMount"] = "F-mount";
})(LensMount || (exports.LensMount = LensMount = {}));
// Media Type Enum
var MediaType;
(function (MediaType) {
    MediaType["sd"] = "SD";
    MediaType["cFast1_1"] = "CFast 1.1";
    MediaType["cFast2_0"] = "CFast 2.0";
    MediaType["arriCodex"] = "Arri Codex";
    MediaType["uhsIISDXC"] = "UHS-II SDXC";
    MediaType["expressP2"] = "expressP2";
    MediaType["cfexpress2_0TypeB"] = "CF Express 2.0 Type B";
    MediaType["vpg400"] = "VPG 400";
    MediaType["cfExpressTypeA"] = "CF Express Type A";
    MediaType["cfExpressTypeB"] = "CF Express Type B";
    MediaType["cFast"] = "CFast";
    MediaType["sdCard"] = "SD Card";
    MediaType["sxs"] = "SxS";
    MediaType["xqd"] = "XQD";
    MediaType["microSD"] = "MicroSD";
    MediaType["mm8"] = "8mm";
    MediaType["mm16"] = "16mm";
    MediaType["mm35"] = "35mm";
    MediaType["mm60"] = "60mm";
    MediaType["cfCard"] = "CF Card";
    MediaType["sdxc"] = "SDXC";
    MediaType["usbC"] = "USB-C";
    MediaType["msProDuo"] = "MS PRO Duo";
    MediaType["expCard34"] = "ExpressCard/34";
    MediaType["axsMemASeries"] = "AXS Memory A-Series";
    MediaType["codexCompactDrive"] = "Codex Compact Drive";
    MediaType["noOnboardMedia"] = "No Onboard Media";
    MediaType["ssd"] = "SSD";
    MediaType["hdd"] = "HDD";
    MediaType["axs"] = "AXS";
})(MediaType || (exports.MediaType = MediaType = {}));
// Type Enum
var Type;
(function (Type) {
    Type["panels"] = "Panels";
    Type["tubes"] = "Tubes";
    Type["fresnel"] = "Fresnel";
    Type["pointSource"] = "Point-Source";
    Type["ellipsoidal"] = "Ellipsoidal";
    Type["parabolic"] = "Parabolic";
    Type["flood"] = "Flood";
    Type["openFace"] = "Open Face";
    Type["blinders"] = "Blinders";
    Type["floodLights"] = "Flood Lights";
    Type["parLights"] = "Par Lights";
    Type["specialtyLights"] = "Specialty Lights";
    Type["washLights"] = "Wash Lights";
    Type["strobeLights"] = "Strobe Lights";
    Type["uplighting"] = "Uplighting";
    Type["box"] = "Box";
    Type["medium"] = "Medium";
    Type["prime"] = "Prime";
    Type["standard"] = "Standard";
    Type["wide"] = "Wide";
    Type["zoom"] = "Zoom";
    Type["anamorphic"] = "Anamorphic";
    Type["boxer"] = "Boxer";
    Type["d75LE"] = "D75LE";
    Type["dle"] = "DLE";
    Type["dlp"] = "DLP";
    Type["elw"] = "ELW";
    Type["hSeries"] = "H Series";
    Type["ilsLens"] = "ILS Lens";
    Type["tld"] = "TLD";
    Type["tldhb"] = "TLDHB";
    Type["productionSwitchers"] = "Production Switchers";
    Type["videoRouter"] = "Video Router";
    Type["wireless"] = "Wireless";
    Type["mechanical"] = "Mechanical";
    Type["headphones"] = "Headphones";
    Type["headphoneAmps"] = "Headphone Amp";
    Type["towable"] = "Towable";
    Type["portable"] = "Portable";
    Type["truck"] = "Truck";
    Type["stationary"] = "Stationary";
})(Type || (exports.Type = Type = {}));
// Sensor Coverage Enum
var SensorCoverage;
(function (SensorCoverage) {
    SensorCoverage["twoThirds"] = "2/3\"";
    SensorCoverage["fullFrame"] = "Full Frame";
    SensorCoverage["super35"] = "Super 35mm";
    SensorCoverage["aps_c"] = "APS-C";
    SensorCoverage["one"] = "1\"";
    SensorCoverage["oneThird"] = "1/3\"";
    SensorCoverage["oneHalf"] = "1/2\"";
    SensorCoverage["oneTwoAndHalf"] = "1/2.5\"";
    SensorCoverage["oneOneSeventh"] = "1/1.7\"";
    SensorCoverage["oneTwoEightSixth"] = "1/2.86\"";
})(SensorCoverage || (exports.SensorCoverage = SensorCoverage = {}));
// Size Enum
var Size;
(function (Size) {
    Size["greaterThan32"] = ">32\"";
    Size["from8To32"] = "8\"-32\"";
    Size["lessThan8"] = "<8\"";
    Size["size12"] = "12\"";
    Size["size20"] = "20\"";
    Size["size24"] = "24\"";
    Size["size30"] = "30\"";
    Size["size20x20"] = "20\"x20\"";
    Size["size12x20"] = "12\"x20\"";
    Size["size12x12"] = "12\"x12\"";
    Size["size8x8"] = "8\"x8\"";
    Size["size6x6"] = "6\"x6\"";
    Size["size48x48"] = "48\"x48\"";
    Size["size36x36"] = "36\"x36\"";
    Size["size36x24"] = "36\"x24\"";
    Size["size24x24"] = "24\"x24\"";
    Size["size24x12"] = "24\"x12\"";
    Size["misc"] = "Misc";
})(Size || (exports.Size = Size = {}));
// Data Cables Enum
var DataCables;
(function (DataCables) {
    DataCables["hdmi"] = "HDMI";
    DataCables["fiber"] = "Fiber";
    DataCables["sdi"] = "SDI";
    DataCables["triax"] = "Triax";
    DataCables["both"] = "Both";
})(DataCables || (exports.DataCables = DataCables = {}));
// Hoist Capacity Enum
var HoistCapacity;
(function (HoistCapacity) {
    HoistCapacity["quarterTon"] = "1/4 Ton";
    HoistCapacity["halfTon"] = "1/2 Ton";
    HoistCapacity["oneTon"] = "1 Ton";
    HoistCapacity["twoTon"] = "2 Ton";
})(HoistCapacity || (exports.HoistCapacity = HoistCapacity = {}));
// Style Enum
var Style;
(function (Style) {
    Style["sevenPin"] = "7-Pin";
    Style["dualTwist"] = "Dual Twist";
    Style["lamp"] = "Lamp";
    Style["laser"] = "Laser";
})(Style || (exports.Style = Style = {}));
// Power Requirement Enum
var PowerRequirement;
(function (PowerRequirement) {
    PowerRequirement["v100_240"] = "100-240v";
    PowerRequirement["v120_240"] = "120-240v";
    PowerRequirement["v200_240"] = "200-240v";
    PowerRequirement["v205_240"] = "205-240v";
})(PowerRequirement || (exports.PowerRequirement = PowerRequirement = {}));
// Generator Wattage Enum
var Wattage;
(function (Wattage) {
})(Wattage || (exports.Wattage = Wattage = {}));
// Brightness Enum
var Brightness;
(function (Brightness) {
    Brightness["b0_1999"] = "0-1999";
    Brightness["b2000_3999"] = "2000-3999";
    Brightness["b4000_5999"] = "4000-5999";
    Brightness["b6K_10K"] = "6K-10K";
    Brightness["b10K_15K"] = "10K-15K";
    Brightness["b15K_25K"] = "15K-25K";
    Brightness["b25K_40K"] = "25K-40K";
    Brightness["b40K_60K"] = "40K-60K";
    Brightness["b60K_plus"] = "60K+";
})(Brightness || (exports.Brightness = Brightness = {}));
// Screen Ratio Enum
var ScreenRatio;
(function (ScreenRatio) {
    ScreenRatio["oneToOne"] = "1:1";
    ScreenRatio["twoToOne"] = "2:1";
    ScreenRatio["threeToOne"] = "3:1";
    ScreenRatio["fourToOne"] = "4:1";
    ScreenRatio["sixteenToNine"] = "16:9";
})(ScreenRatio || (exports.ScreenRatio = ScreenRatio = {}));
// Chip Quantity Enum
var ChipQuantity;
(function (ChipQuantity) {
    ChipQuantity["one"] = "1";
    ChipQuantity["three"] = "3";
})(ChipQuantity || (exports.ChipQuantity = ChipQuantity = {}));
//Environment Enum
var Environment;
(function (Environment) {
    Environment["indoor"] = "Indoor";
    Environment["outdoor"] = "Outdoor";
})(Environment || (exports.Environment = Environment = {}));
var TruckSize;
(function (TruckSize) {
    TruckSize["sprinter"] = "Sprinter";
    TruckSize["truck12"] = "12' Truck";
    TruckSize["trailer16"] = "16' Trailer/Straight/Box";
    TruckSize["truck18"] = "18' Truck";
    TruckSize["truck20"] = "20' Truck";
    TruckSize["truck21"] = "21' Truck";
    TruckSize["truck24"] = "24' Truck";
    TruckSize["truck26"] = "26' Truck";
    TruckSize["truck27"] = "27' Truck";
    TruckSize["truck28"] = "28' Truck";
    TruckSize["truck30"] = "30' Truck";
    TruckSize["truck32"] = "32' Truck";
    TruckSize["truck34"] = "34' Truck";
    TruckSize["truck36"] = "36' Truck";
    TruckSize["straightTruck40"] = "40' Straight Truck";
    TruckSize["expandoTruck40"] = "40' Expando Truck";
    TruckSize["expandoTruck43"] = "43' Expando Truck";
    TruckSize["doubleExpandoTruck44"] = "44' Double Expando Truck";
    TruckSize["truck45"] = "45' Truck";
    TruckSize["trailer48"] = "48' Trailer";
    TruckSize["straightTruck49"] = "49' Straight Truck";
    TruckSize["expandoTruck51"] = "51' Expando Truck";
    TruckSize["doubleExpando53"] = "53' Double Expando";
    TruckSize["expandoTruck53"] = "53' Expando Truck";
    TruckSize["dualExpandoTruck53"] = "53' Dual Expando Truck";
    TruckSize["truck53"] = "53' Truck";
    TruckSize["doubleExpandoTruck56"] = "56' Double Expando Truck";
    TruckSize["tripleExpando59"] = "59' Triple Expando";
    TruckSize["tripleExpando60"] = "60' Triple Expando";
})(TruckSize || (exports.TruckSize = TruckSize = {}));
var TruckCameraResolution;
(function (TruckCameraResolution) {
    TruckCameraResolution["HD"] = "HD";
    TruckCameraResolution["HD_1080P"] = "HD/1080P";
    TruckCameraResolution["HD_1080I"] = "HD/1080i";
    TruckCameraResolution["HD_4K"] = "HD/4K";
    TruckCameraResolution["HD_1080P_3G"] = "HD/1080p 3G";
    TruckCameraResolution["HD_1080P_3G_UHD_HDR"] = "HD/1080p 3G/ UHD/HDR";
    TruckCameraResolution["HD_1080P_3G_UHD_HDR_SMPTE2110_IP"] = "HD/1080p 3G/ UHD/HDR SMPTE 2110 IP";
    TruckCameraResolution["HD_1080P_4K_HDR"] = "HD/1080p/4K/HDR";
    TruckCameraResolution["IP_HD_1080P_4K_HDR"] = "IP/HD/1080p/4K/ HDR";
    TruckCameraResolution["_720P_1080I"] = "720p/1080i";
    TruckCameraResolution["_720P_1080I_1080P_HDR"] = "720p/1080i/1080p HDR";
})(TruckCameraResolution || (exports.TruckCameraResolution = TruckCameraResolution = {}));
exports.categoryToTypes = {
    camera: ['Wireless', 'Mechanical', "Box", "Medium", "Prime", "Standard", "Wide", "Zoom", "Anamorphic"],
    audio: ['Headphones', 'Headphone Amp'],
    lighting: [
        "Ellipsoidal",
        "Parabolic",
        "Fresnel",
        "Point-Source",
        "Flood",
        "Open Face",
        "Blinders",
        "Flood Lights",
        "Par Lights",
        "Specialty Lights",
        "Wash Lights",
        "Strobe Lights",
        "Uplighting",
        "Tubes",
        "Panels",
        "Floor",
        "Processing",
        "Wall Panels",
    ],
    production_supplies: ['Boxer', 'D75LE', 'DLE', 'DLP', 'ELW', 'H Series', 'ILS Lens', 'TLD', 'TLDHB'],
    video: ['Production Switchers', 'Video Router'],
    electric: ['Towable', 'Portable', 'Truck', 'Stationary']
};
var StateAndProvinceAbbreviations;
(function (StateAndProvinceAbbreviations) {
    StateAndProvinceAbbreviations["AL"] = "AL";
    StateAndProvinceAbbreviations["AK"] = "AK";
    StateAndProvinceAbbreviations["AZ"] = "AZ";
    StateAndProvinceAbbreviations["AR"] = "AR";
    StateAndProvinceAbbreviations["CA"] = "CA";
    StateAndProvinceAbbreviations["CO"] = "CO";
    StateAndProvinceAbbreviations["CT"] = "CT";
    StateAndProvinceAbbreviations["DE"] = "DE";
    StateAndProvinceAbbreviations["FL"] = "FL";
    StateAndProvinceAbbreviations["GA"] = "GA";
    StateAndProvinceAbbreviations["HI"] = "HI";
    StateAndProvinceAbbreviations["ID"] = "ID";
    StateAndProvinceAbbreviations["IL"] = "IL";
    StateAndProvinceAbbreviations["IN"] = "IN";
    StateAndProvinceAbbreviations["IA"] = "IA";
    StateAndProvinceAbbreviations["KS"] = "KS";
    StateAndProvinceAbbreviations["KY"] = "KY";
    StateAndProvinceAbbreviations["LA"] = "LA";
    StateAndProvinceAbbreviations["ME"] = "ME";
    StateAndProvinceAbbreviations["MD"] = "MD";
    StateAndProvinceAbbreviations["MA"] = "MA";
    StateAndProvinceAbbreviations["MI"] = "MI";
    StateAndProvinceAbbreviations["MN"] = "MN";
    StateAndProvinceAbbreviations["MS"] = "MS";
    StateAndProvinceAbbreviations["MO"] = "MO";
    StateAndProvinceAbbreviations["MT"] = "MT";
    StateAndProvinceAbbreviations["NE"] = "NE";
    StateAndProvinceAbbreviations["NV"] = "NV";
    StateAndProvinceAbbreviations["NH"] = "NH";
    StateAndProvinceAbbreviations["NJ"] = "NJ";
    StateAndProvinceAbbreviations["NM"] = "NM";
    StateAndProvinceAbbreviations["NY"] = "NY";
    StateAndProvinceAbbreviations["NC"] = "NC";
    StateAndProvinceAbbreviations["ND"] = "ND";
    StateAndProvinceAbbreviations["OH"] = "OH";
    StateAndProvinceAbbreviations["OK"] = "OK";
    StateAndProvinceAbbreviations["OR"] = "OR";
    StateAndProvinceAbbreviations["PA"] = "PA";
    StateAndProvinceAbbreviations["RI"] = "RI";
    StateAndProvinceAbbreviations["SC"] = "SC";
    StateAndProvinceAbbreviations["SD"] = "SD";
    StateAndProvinceAbbreviations["TN"] = "TN";
    StateAndProvinceAbbreviations["TX"] = "TX";
    StateAndProvinceAbbreviations["UT"] = "UT";
    StateAndProvinceAbbreviations["VT"] = "VT";
    StateAndProvinceAbbreviations["VA"] = "VA";
    StateAndProvinceAbbreviations["WA"] = "WA";
    StateAndProvinceAbbreviations["WV"] = "WV";
    StateAndProvinceAbbreviations["WI"] = "WI";
    StateAndProvinceAbbreviations["WY"] = "WY";
    StateAndProvinceAbbreviations["DC"] = "DC";
    StateAndProvinceAbbreviations["AB"] = "AB";
    StateAndProvinceAbbreviations["BC"] = "BC";
    StateAndProvinceAbbreviations["MB"] = "MB";
    StateAndProvinceAbbreviations["NB"] = "NB";
    StateAndProvinceAbbreviations["NL"] = "NL";
    StateAndProvinceAbbreviations["NS"] = "NS";
    StateAndProvinceAbbreviations["ON"] = "ON";
    StateAndProvinceAbbreviations["PE"] = "PE";
    StateAndProvinceAbbreviations["QC"] = "QC";
    StateAndProvinceAbbreviations["SK"] = "SK";
    StateAndProvinceAbbreviations["NT"] = "NT";
    StateAndProvinceAbbreviations["NU"] = "NU";
    StateAndProvinceAbbreviations["YT"] = "YT";
})(StateAndProvinceAbbreviations || (exports.StateAndProvinceAbbreviations = StateAndProvinceAbbreviations = {}));
(function (Wattage) {
    Wattage["range1kTo5k"] = "1000-5000W";
    Wattage["range5kTo10k"] = "5000-10000W";
    Wattage["range10kTo20k"] = "10kW-20kW";
    Wattage["range20kTo30k"] = "20kW-30kW";
    Wattage["range30kTo50k"] = "30kW-50kW";
    Wattage["range50kTo70k"] = "50kW-70kW";
    Wattage["range70kTo100k"] = "70kW-100kW";
    Wattage["range100kTo200k"] = "100kW-200kW";
    Wattage["range200kTo300k"] = "200kW-300kW";
    Wattage["range300kTo400k"] = "300kW-400kW";
    Wattage["range400kTo500k"] = "400kW-500kW";
    Wattage["range500kTo600k"] = "500kW-600kW";
    Wattage["range600kTo700k"] = "600kW-700kW";
    Wattage["range700kTo800k"] = "700kW-800kW";
})(Wattage || (exports.Wattage = Wattage = {}));
var Amperage;
(function (Amperage) {
    Amperage["range0To49"] = "0-49 Amps";
    Amperage["range50To199"] = "50-199 Amps";
    Amperage["range200To299"] = "200-299 Amps";
    Amperage["range300To399"] = "300-399 Amps";
    Amperage["range400To599"] = "400-599 Amps";
    Amperage["range600To999"] = "600-999 Amps";
    Amperage["range1000To2999"] = "1000-2999 Amps";
    Amperage["range3000To4999"] = "3000-4999 Amps";
    Amperage["range5000To7999"] = "5000-7999 Amps";
    Amperage["range8000To11999"] = "8000-11999 Amps";
    Amperage["range12000To15999"] = "12000-15999 Amps";
    Amperage["range16000To19999"] = "16000-19999 Amps";
    Amperage["range20000To23999"] = "20000-23999 Amps";
    Amperage["range24000To27999"] = "24000-27999 Amps";
    Amperage["range28000To31999"] = "28000-31999 Amps";
})(Amperage || (exports.Amperage = Amperage = {}));
var Voltage;
(function (Voltage) {
    Voltage["v120"] = "120V";
    Voltage["v208"] = "208V";
    Voltage["v240"] = "240V";
    Voltage["v400"] = "400V";
    Voltage["v415"] = "415V";
    Voltage["v460"] = "460V";
    Voltage["v480"] = "480V";
    Voltage["v600"] = "600V";
    Voltage["v120_208"] = "120/208V";
    Voltage["v120_240"] = "120/240V";
    Voltage["v208_240"] = "208/240V";
    Voltage["v208_480"] = "208/480V";
    Voltage["v277_480"] = "277/480V";
    Voltage["v480_600"] = "480/600V";
})(Voltage || (exports.Voltage = Voltage = {}));
var Phases;
(function (Phases) {
    Phases["singlePhase"] = "Single Phase";
    Phases["threePhase"] = "3-Phase";
    Phases["singleAndThreePhase"] = "Single Phase & 3-Phase";
})(Phases || (exports.Phases = Phases = {}));
var FuelType;
(function (FuelType) {
    FuelType["gas"] = "Gasoline";
    FuelType["diesel"] = "Diesel";
    FuelType["propane"] = "Propane";
    FuelType["solar"] = "Solar";
})(FuelType || (exports.FuelType = FuelType = {}));
class EqipProduct extends model_core_1.Product {
    constructor(data, olMap) {
        super(data, olMap || index_1.olm);
        this.page_description = [];
        this.key_features = [];
        this.image_urls = [];
        this.manufacturer_url = '';
        this.similar = [];
        this.createdBy = {};
        this.verified = false;
        this.custom_sort_order = null;
        this.properties = {
            category: null,
            subcategory_lvl1: null,
            subcategory_lvl2: null,
            subcategory_lvl3: null,
            subcategory_lvl4: "",
            brand: null,
            sensor_size: null,
            resolution: null,
            frame_rate: null,
            lens_mount: null,
            media_type: null,
            weight: null,
            vendors: [],
            f_stop_min: null,
            f_stop_max: null,
            t_stop_min: null,
            t_stop_max: null,
            focal_length_min: null,
            focal_length_max: null,
            storage_capacity: null,
            zoom: null,
            sensor_coverage: null,
            size: null,
            type: [],
            monitor_cage: false,
            data_cables: [],
            camera_storageType: null,
            dimensions: null,
            hoist_capacity: null,
            style: null,
            power_requirements: null,
            brightness: null,
            lumen: null,
            refresh_rate: null,
            environment: null,
            screen_ratio: null,
            chip_quantity: null,
            live_audio: false,
            price: 0,
            load_capacity: null,
            truck_camera_resolution: null,
            truck_size: null,
            truck_audio: [],
            truck_cameras: [],
            truck_lenses: [],
            truck_graphics: [],
            truck_microphones: [],
            truck_switches: [],
            camera_quantity: null,
            location: null,
            truck_router: [],
            truck_replay: [],
            truck_other: [],
            city: null,
            state: null,
            wattage: null,
            voltage: null,
            amperage: null,
            phases: null,
            fuel_type: null,
        };
        this._m = ['name', 'sku', 'image_urls', 'verified'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, model_core_1.Product);
        this._s = ['name', 'properties', 'sku', 'image_urls', 'custom_sort_order', 'url'];
    }
    removeUnwantedProperties(jsonRepresentation) {
        const propertiesToRemove = ['weight', 'weight_ounces', 'cost', 'dimensions', 'price', 'address'];
        propertiesToRemove.forEach(property => {
            delete jsonRepresentation[property];
        });
    }
    toString() {
        return `Product: ${this.name}`;
    }
    toJSON() {
        let jsonRepresentation = super.toJSON();
        this.removeUnwantedProperties(jsonRepresentation);
        return jsonRepresentation;
    }
    toMinJSON(ignoreDocRef) {
        let minJSONRepresentation = super.toMinJSON(ignoreDocRef);
        this.removeUnwantedProperties(minJSONRepresentation);
        const subcategories = [];
        for (let i = 1; i <= 4; i++) {
            const subcategoryLevel = `subcategory_lvl${i}`;
            if (this.properties[subcategoryLevel]) {
                subcategories.push(this.properties[subcategoryLevel]);
            }
        }
        if (this.properties) {
            minJSONRepresentation.properties = {
                category: this.properties.category,
                brand: this.properties.brand,
                price: this.properties.price,
                subcategories
            };
        }
        return minJSONRepresentation;
    }
}
exports.EqipProduct = EqipProduct;
index_1.olm.products = (ref, map) => {
    return new EqipProduct(ref, map);
};
