import {
    Directive,
    AfterViewInit,
} from '@angular/core';

@Directive({
    standalone: true,
    selector: '[removeDuplicates]'
})
export class RemoveDuplicatesDirective implements AfterViewInit {

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.removeDuplicatesByTag('eqip-new-footer',false);
            this.removeDuplicatesByTag('new-eqip-nav-header',true);
        }, 100);
    }
    private removeDuplicatesByTag(tagName: string,first:boolean) {
        const elements = Array.from(document.getElementsByTagName(tagName));
        if (elements.length > 1) {
            if (elements.length > 1) {
                const elementsToRemove = first
                    ? elements.slice(1)
                    : elements.slice(0, -1);

                elementsToRemove.forEach(el => {
                    el.parentNode?.removeChild(el);
                });
            }
        }
    }

}
